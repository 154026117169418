import React, { useState, useEffect } from "react";
import "./Navbar.css"; // Import your CSS file for styling
import { BiMenu, BiSolidDownArrow } from "react-icons/bi";
import { CiMenuBurger } from "react-icons/ci";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
// import logoimg from '../../assets/Navbar/logo.png'

function Navbar() {

  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [sidebar, setSidebar] = useState("");
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  function toggleTransition() {
    if (sidebar == "slide-left") setSidebar("");
    else setSidebar("slide-left");
  }
  const [isAEDClicked, setIsAEDClicked] = useState(false);

  const handleAEDClick = () => {
    setIsAEDClicked(!isAEDClicked);
  };

  const [openList, setOpenList] = useState(null);
  const toggleList = (listName) => {
    if (openList === listName) {
      setOpenList(null);
    } else {
      setOpenList(listName);
    }
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <div className={`navbar-logo-header ${isMobile ? "left" : ""}`}>
            <Link to="/">
              <img alt="" src="/assets/MüllerCo_Logo.png" />
            </Link>
          </div>
          {/* <div
            className={`navbar-toggle  ${isOpen ? "active" : ""}`}
            onClick={toggleNavbar}
          >
            <BiMenu />
          </div> */}
          <div
            className="offcanvas-navbar-desktop-menu nav-web" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={toggleTransition}
          >
            <BiMenu />
          </div>



          <div className={`navbar-links nav-responsive ${isOpen ? "active" : ""}`}>
            <div>
              <ul className="navbar-list">
                <li className="navbar-item">
                  <Link to="/buy-property" className="navbar-link">BUY PROPERTY</Link>
                </li>
                <li className="navbar-item">
                  <Link to="/investment" className="navbar-link">INVESTMENT</Link>
                </li>
                <li className="navbar-item">
                  <Link to="/about-us" className="navbar-link">ABOUT</Link>
                </li>
                <li className="navbar-item">
                  <Link to="/blogs" className="navbar-link">BLOG</Link>
                </li>
                {/* <li className="navbar-item"><a href="#" className="navbar-link">ABOUT</a></li> */}
              </ul>
            </div>
            <div className={`navbar-logo nav-responsive ${isMobile ? "left" : ""}`}>
              <Link to="/">
                <img alt="" src="/assets/MüllerCo_Logo.png" />
              </Link>
            </div>
            <div style={{ display: "flex" }}>
              <ul className="navbar-list nav-responsive">
                <li className="navbar-item">
                  <Link to="/contact-us" className="navbar-link">CONTACT</Link>
                </li>
                <li className="navbar-item instant-valuation">
                  <Link to="/instant-valuation" className="navbar-link">Instant Valuation</Link>
                </li>
                <li className="">
                  <div className="dropdown show">
                    <div className={`pdropdown ${isAEDClicked ? "expanded" : ""}`}>
                      <div className={`option selected ${isAEDClicked ? "selectedAED" : ""}`} onClick={handleAEDClick} data-value="AED">
                        <BiSolidDownArrow style={{ color: '#D0AE83', position: 'absolute', right: '10px' }} />
                        <div class="symbol">
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.11066 0C4.0998 0 0 4.0998 0 9.11066C0 14.1215 4.0998 18.2213 9.11066 18.2213C14.1215 18.2213 18.2213 14.1215 18.2213 9.11066C18.2213 4.0998 14.1215 0 9.11066 0ZM9.11066 16.7029C4.93494 16.7029 1.51844 13.2864 1.51844 9.11066C1.51844 4.93494 4.93494 1.51844 9.11066 1.51844C13.2864 1.51844 16.7029 4.93494 16.7029 9.11066C16.7029 13.2864 13.2864 16.7029 9.11066 16.7029Z" fill="#D0AE83" />
                            <path d="M11.7465 5.59683C11.7465 5.59683 13.4272 5.96824 13.1277 11.1193H10.2841C9.88947 11.1193 9.57842 10.7804 9.61324 10.3881L9.72002 9.16007H9.94751C9.94751 9.40614 10.1471 9.60577 10.3932 9.60577H12.5753C12.5753 9.60577 12.5242 8.11315 11.3589 7.4214L11.7465 5.59683ZM5.88982 11.5859C5.88982 11.5859 6.28213 10.8593 6.41212 10.3045C6.49801 9.9354 6.57229 9.54077 6.51194 8.62849C6.42141 7.26587 5.81322 3.99976 5.81322 3.99976C5.81322 3.99976 5.18414 4.87722 5.12146 5.64326C5.05879 6.4093 5.33038 7.27747 5.46734 7.79281C5.69947 8.65867 5.89911 10.1559 5.54162 11.4164L5.88982 11.5859ZM4.83594 14C4.83594 14 5.25146 13.8143 5.6159 13.7772C5.98035 13.7401 6.42373 13.5404 6.47944 13.2317C6.47944 13.2317 6.00821 13.3431 5.65305 13.1737C5.37449 13.0413 5.42091 12.8835 5.46966 12.8231C5.51609 12.7674 5.62983 12.7071 5.87589 12.8162C6.11963 12.9276 6.26123 12.8046 6.25891 12.5794C6.25659 12.3542 6.07785 12.273 5.89446 12.2823C5.71108 12.2915 5.06807 12.5144 5.05879 13.0901C5.05414 13.4986 5.38145 13.5172 5.38145 13.5172C5.38145 13.5172 5.01004 13.624 4.83594 14ZM8.91916 11.2168H7.22923V12.9067H8.91916V11.2168Z" fill="#D0AE83" />
                          </svg>
                        </div>
                        <div class="dcurrency">AED</div>
                      </div>
                      {isAEDClicked && (
                        <div className="option" data-value="USD">
                          <div class="symbol">
                            <svg
                              width="29"
                              height="29"
                              viewBox="0 0 29 29"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.8035 13.7416C16.1509 13.3789 15.4591 13.1112 14.773 12.8267C14.3769 12.6649 13.9976 12.4697 13.6628 12.2019C13.0046 11.6719 13.1273 10.8184 13.9027 10.4781C14.1203 10.3833 14.349 10.3498 14.5833 10.3387C15.4814 10.2885 16.3294 10.4558 17.1438 10.8463C17.5455 11.0416 17.6794 10.9802 17.8188 10.5618C17.9639 10.1155 18.0866 9.66366 18.2149 9.21738C18.3042 8.91614 18.1926 8.71531 17.9081 8.59259C17.3893 8.36387 16.8538 8.19651 16.2903 8.11283C15.5595 7.99568 15.5595 7.99568 15.554 7.25932C15.5484 6.21614 15.5484 6.21614 14.5108 6.22172C14.3602 6.22172 14.2095 6.21614 14.0589 6.22172C13.5736 6.23845 13.4899 6.32213 13.4788 6.80746C13.4732 7.02502 13.4788 7.24816 13.4788 7.46573C13.4732 8.11841 13.4732 8.10725 12.8484 8.3304C11.3422 8.87709 10.4106 9.90354 10.3102 11.5492C10.2209 13.0052 10.9796 13.987 12.1734 14.7011C12.9097 15.1418 13.7242 15.4039 14.5052 15.7498C14.812 15.8837 15.1021 16.0399 15.3531 16.2519C16.1062 16.8711 15.9668 17.9031 15.0742 18.2936C14.5945 18.5 14.0924 18.5558 13.5792 18.4889C12.7814 18.3885 12.0172 18.182 11.3031 17.8083C10.8847 17.5907 10.762 17.6465 10.617 18.1039C10.4942 18.5 10.3827 18.8961 10.2767 19.2922C10.1316 19.8277 10.1818 19.956 10.6951 20.2015C11.3422 20.5139 12.0395 20.6756 12.748 20.7928C13.3002 20.882 13.3226 20.9044 13.3281 21.479C13.3281 21.7411 13.3337 22.0033 13.3337 22.2599C13.3393 22.5891 13.4955 22.7787 13.8358 22.7843C14.2207 22.7899 14.6056 22.7899 14.9905 22.7843C15.3029 22.7787 15.4647 22.6058 15.4703 22.2878C15.4703 21.9308 15.487 21.5738 15.4759 21.2168C15.4591 20.8542 15.6153 20.6701 15.9668 20.5752C16.7701 20.3577 17.4562 19.9225 17.9862 19.281C19.4366 17.4959 18.8787 14.8907 16.8035 13.7416Z"
                                fill="#D0AE83"
                              ></path>
                              <path
                                d="M14.5 0C6.525 0 0 6.525 0 14.5C0 22.475 6.525 29 14.5 29C22.475 29 29 22.475 29 14.5C29 6.525 22.475 0 14.5 0ZM14.5 26.5833C7.85417 26.5833 2.41667 21.1458 2.41667 14.5C2.41667 7.85417 7.85417 2.41667 14.5 2.41667C21.1458 2.41667 26.5833 7.85417 26.5833 14.5C26.5833 21.1458 21.1458 26.5833 14.5 26.5833Z"
                                fill="#D0AE83"
                              ></path>
                            </svg>
                          </div>
                          <div class="dcurrency">USD</div>
                        </div>
                      )}
                    </div>
                  </div>

                </li>
              </ul>
              <button className="offcanvas-navbar-desktop-menu" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={toggleTransition}>
                <BiMenu />
              </button>
            </div>
          </div>
        </div>

      </nav>


      <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header">
          <div className="">

          </div>
        </div>
        <div className="offcanvas-body">
          <div className="row offcanvas-body-nav">
            <div className="col-lg-4">
              <img alt="" src="/assets/MüllerCo_Logo.png" />
            </div>
            <div className="navbar-offcanvas-close-btn  " aria-label="Close" data-bs-dismiss="offcanvas">
              <IoClose />

            </div>
            <div className="col-lg-6 Menubar-Heading">
              <p>More</p>
              <button className="offcanvas-navbar-desktop-menu" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={toggleTransition} >
                <CiMenuBurger />
              </button>
            </div>
          </div>

          <div>
            <ul tabIndex="0" onClick={() => toggleList('sales')}>
              Sales
              {openList === 'sales' && (
                <>
                  <li>Search Property To Buy</li>
                  <Link to="/buyers-guide">
                    <li>Buyer Guide</li>
                  </Link>
                  <li>Seller Guide</li>
                  <li>Sell My Property</li>
                  <li>Finance Calculator</li>
                </>
              )}
            </ul>
          </div>
          <div>
            <ul tabIndex="0" onClick={() => toggleList('ofPlane')}>
              Off plan & Investments
              {openList === 'ofPlane' && (
                <>
                  <li>Discover Latest Off-Plan Projects</li>
                  <li>Why Off Plan in Dubai</li>
                  <li>Free Investment Guide</li>
                  <li>Finance/ Rental Yield Calculator</li>
                </>
              )}
            </ul>
          </div>
          <div>
            <ul tabIndex="0" onClick={() => toggleList('commercial')}>
              Commercial
              {openList === 'commercial' && (
                <>
                  <li>Search Commercial Property to buy</li>
                  <li>Search Commercial Property to rent</li>
                  <li>About Commercial Properties</li>
                  <li>Landlords/Owners Guide</li>
                  <li>Buyers Guide</li>
                  <li>Tenants/Retailers Guide</li>
                  <li>Finance/ Rental Yield Calculator</li>
                </>
              )}
            </ul>
          </div>


          <div>
            <ul tabIndex="0" onClick={() => toggleList('propertyManagement')}>
              Property Management
              {openList === 'propertyManagement' && (
                <>
                  <li>
                    Manage my Property
                  </li>
                  <li>
                    Our Packages
                  </li>
                </>
              )}

            </ul>

          </div>
          <div>
            <ul tabIndex="0" onClick={() => toggleList('otherServices')}>
              Other Services
              {openList === 'otherServices' && (
                <>
                  <li>
                    Furniture Solutions
                  </li>
                  <li>
                    Mortgage Services
                  </li>
                  <li>
                    Currency Services
                  </li>
                  <li>
                    Conveyancing Services
                  </li>
                </>
              )}
            </ul>
          </div>
          <div>
            <ul tabIndex="0" onClick={() => toggleList('livingDubai')}>
              Living in dubai
              {openList === 'livingDubai' && (
                <>
                  <Link to="/dubai-area-guide">
                    <li>
                      Area Guides
                    </li>
                  </Link>
                  <li>
                    Legal Information
                  </li>
                  <li>
                    Latest News
                  </li>
                </>
              )}
            </ul>
          </div>

          <div>
            <ul tabIndex="0" onClick={() => toggleList('aboutUs')}>
              About Us
              {openList === 'aboutUs' && (
                <>
                  <li>
                    Our Culture
                  </li>
                  <Link to="/our-team">
                    <li>
                      Our Team
                    </li>
                  </Link>
                  <li>
                    Client Reviews
                  </li>
                  <li>
                    Sponsorship & Charity
                  </li>
                  <li>
                    Real Estate Awards
                  </li>
                  <Link to="/careers">
                    <li>
                      Careers
                    </li>
                  </Link>
                  <Link to="/blogs">
                    <li>
                      Blog
                    </li>
                  </Link>
                </>
              )}
            </ul>

          </div>
          <div>
            <ul tabIndex="0" onClick={() => toggleList('contactUs')}>
              Contact Us
              {openList === 'contactUs' && (
                <>
                  <li>
                    Head Office
                  </li>
                  <li>
                    Leave Feedback
                  </li>
                  <li>
                    Valuation Request
                  </li>
                  <li>
                    Press Enquiry
                  </li>
                  <li>
                    Job Application
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>

        <div className="container">
          <div className="row offcanvas-body-footer">
            <div className="col-lg-4 ">
              <h4 className="">
                Market News
              </h4>
            </div>
            <div className="col-lg-4 ">
              <h4>
                Market News
              </h4>
            </div>
            <div className="col-lg-4 ">
              <h4>
                Careers
              </h4>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Navbar;
