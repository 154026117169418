import React from "react";
import "./PropertyListing.css";
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsBuildingsFill } from "react-icons/bs";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

function PropertyListing() {
  const listingPageCard = [
    {
      slug:"property-1",
      price: "AED 10,000,000",
      heading: "Home Name with Address",
      location: "Business Bay",
      desc: "Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloreincididunt ut labore et dolore",
      image: "/assets/listing-hotel.png",
    },
    {
      slug:"property-2",
      price: "AED 10,000,000",
      heading: "Home Name with Address",
      location: "Business Bay",
      desc: "Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloreincididunt ut labore et dolore",
      image: "/assets/listing-hotel.png",
    },
    {
      slug:"property-3",
      price: "AED 10,000,000",
      heading: "Home Name with Address",
      location: "Business Bay",
      desc: "Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloreincididunt ut labore et dolore",
      image: "/assets/listing-hotel.png",
    },
    {
      slug:"property-4",
      price: "AED 10,000,000",
      heading: "Home Name with Address",
      location: "Business Bay",
      desc: "Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloreincididunt ut labore et dolore",
      image: "/assets/listing-hotel.png",
    },
    {
      slug:"property-5",
      price: "AED 10,000,000",
      heading: "Home Name with Address",
      location: "Business Bay",
      desc: "Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloreincididunt ut labore et dolore",
      image: "/assets/listing-hotel.png",
    },
    {
      slug:"property-6",
      price: "AED 10,000,000",
      heading: "Home Name with Address",
      location: "Business Bay",
      desc: "Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloreincididunt ut labore et dolore",
      image: "/assets/listing-hotel.png",
    },
  ];

  return (
    <>
      <Navbar />
      <div className=" listing-page-main row">
        <div className="col-lg-7 col-md-12 col-sm-12">
          <img src="/assets/listing-banner.png" alt="" />
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12">
          <div>
            <img src="/assets/listing-homeicon.png" alt="" />
            <h2 className="">Buy property</h2>
          </div>
          <p>
            Meet our exceptional real estate agents, the driving force behind
            Muller and Co. Specializing in Dubai's property market, they're here
            to assist you with buying, selling, or renting the finest villas,
            apartments, townhouses, plots, and commercial properties.
          </p>
        </div>
      </div>

      <div className="main-container">
        <div className="listing-page-form row">
          <div className="col-lg-3 col-md-4 col-sm-12 ">
            <label htmlFor="">Area</label>
            <div className="select_box">
              <select name="" id="">
                <option value="">Select Location</option>
                <option value="">Select Location</option>
                <option value="">Select Location</option>
              </select>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 listing-vl">
            <label htmlFor="">Property Type</label>
            <div className="select_box">
              <select name="" id="">
                <option value="">Choose Property Type</option>
                <option value="">Choose Property Type</option>
                <option value="">Choose Property Type</option>
              </select>
            </div>
            {/* <div class="vl"></div> */}
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 row listing-vl">
            <div className="col-9">
              <label htmlFor="">Developer</label>
              <div className="select_box ">
                <select name="" id="">
                  <option value="">Choose Developer </option>
                  <option value="">Choose a Developer</option>
                  <option value="">Choose a Developer</option>
                </select>
              </div>
            </div>
            <div className="col-3"></div>
            {/* <div class="vl"></div> */}
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 row listing-vl">
            <div className="col-9">
              <label htmlFor="">Price Range</label>
              <div className="select_box ">
                <select name="" id="">
                  <option value="">Choose a Range </option>
                  <option value="">Choose a Range</option>
                  <option value="">Choose a Range</option>
                </select>
              </div>
            </div>
            <div className="col-3">
              <button className="listing-page-search-btnn">
                <BiSearch />
              </button>
            </div>
            {/* <div class="vl"></div> */}
          </div>
        </div>

        <div className="row">
          {listingPageCard.map((data, i) => (
            <div className="col-lg-4 col-md-6 col-12 mt-5">
              <div className="listing-card">
                <Link to={`/property/detail/${data.slug}`}>
                  <div
                    className="listing-page-card-img"
                    style={{ backgroundImage: `url(${data.image})` }}
                  >
                    <div className="aed">
                      <p>from {data.price}</p>
                    </div>
                  </div>
                </Link>
                <div className="mx-3">
                  <h5 className="fw-bold ">{data.heading}</h5>
                  <FaMapMarkerAlt className="me-1    " /> {data.location}
                  <p>{data.desc}</p>
                  <div className="row mt-2 p-2">
                    <div className="col">
                      <BsBuildingsFill className="me-1" />
                      Lorem ipsum
                    </div>
                    <div className="col">
                      <img
                        src="/assets/listing-key.png"
                        alt=""
                        className="me-1"
                      />{" "}
                      Q1 2026
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="popular-search">
        <p>Popular Searches of Properties for Sale in Dubai</p>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-8">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 ">
                <div className="for-sale-card">
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 ">
                <div className="for-sale-card">
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 ">
                <div className="for-sale-card">
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                  <p>Private properties for sale in Dubai</p>
                </div>
              </div>

              <h5>Buying a Property in Dubai</h5>
              <p>
                It’s no surprise that the properties in Dubai are one of the
                safest investments one could make. Liberal freehold property
                policies combined with a wide selection of apartments, villas,
                townhouses, penthouses and offices for sale, there's more than
                enough choices for investors looking to purchase property within
                this emirate. However, there is another more convincing reason
                that one will be compelled to buy real estate in Dubai: many
                investors overlook the economy of Dubai when making investment
                decisions, whether it’s a big-time investor or someone looking
                to purchase property for personal use. Purchasing a villa or
                apartment in Dubai can significantly contribute to its economy.
                Another solid reason why Dubai is a good choice for investment
                is its diversified economy. Unlike other Khaleeji nations and
                emirates, Dubai's economy does not rely on hydrocarbons so
                fluctuations in oil prices have little or no effect on its
                overall economic condition. Other reasons why someone benefits
                from buying property here is the assurance of overall safety and
                security within the country. Despite being in the middle of a
                volatile region, Dubai is one of the safest places to live in.
                Many people choose to move to this emirate due to safety and
                security and most of the time, they end up buying an apartment
                or villa to live in or start their own business by purchasing an
                office or retail shop. Finally, many have found that buying and
                selling property here is one of the best ways to earn a lot of
                money in a short span of time.
              </p>

              <p className="m-0">Types of Properties in Dubai</p>
              <p>
                Dubai has properties that come in all shapes and sizes and will
                mostly match the budgets of those interested in purchasing them.
                Starting off with studio apartments, the most popular type comes
                with 1 bath and around 359 sq. ft. of floor space. One-bed
                apartments seem to be the most popular choice here, with a
                floor-plan that includes 2 baths and 785 sq. ft. of floor space.
                Those planning on buying a 2-bed flat in Dubai will find that
                the most in-demand unit will have a floor plan consisting of 3
                baths and 1,636 sq. ft. of floor space. Those looking for
                something larger, perhaps to accommodate their growing family,
                could consider a 3-bed residential flat in Dubai or even a 4-bed
                if they require even more space. The most popular types
                typically come with 3 baths and 1,489 sq. ft. of floor space and
                4 baths with 2,421 sq. ft. of floor space, respectively. For
                those looking into buying a villa in Dubai of their own, they
                will find choices ranging from 2-beds to 4-bed villas.
                Accordingly, the most popular 2-bed villa will come with 3 baths
                and around 1,800 sq. ft. of floor space. If one is looking to
                buy a 3-bed house in Dubai, they should look into units that
                come with 3 baths and an average floor space of 2,300 sq. ft.
                When it comes to 4-bed houses, the most popular floor-plan comes
                with 6 baths and 4,000 sq. ft. of floor space. In the largest
                category, one will find the 5 and 6-bed villas. These will come
                with 6 baths with living spaces of 5,000 sq. ft. and 7-baths
                with 5,500 sq. ft. of floor space, respectively. In the
                commercial property domain, the most popular type of office for
                sale is a unit that comes with 1,204 sq. ft. of floor space
                located in Barsha Heights, Dubai. For those looking at
                purchasing their own retail space, the most in-demand floor will
                be found in Business Bay and it will offer a floor space of
                1,267 sq. ft.
              </p>

              <p className="m-0">Popular Areas of Dubai</p>
              <p>
                At top of the list is the community of Dubai Sports City which
                is a suburb on the outskirts of Dubai. This newly established
                community spans an area of 50 million sq. km and provides an
                affordable means of purchasing property, while offering large
                living spaces with cleaner and greener surroundings at the same
                time. On second place is the community of Dubai Silicon Oasis.
                The reason for its popularity is not only due to its low
                property prices but also its location. For one, it is located
                close to Dubai Academic City, home to some of the best colleges
                and universities in Dubai. Third on the list is the community of
                International City, which is themed on architectural designs of
                various countries. Moreover, given that this community is also
                on the outskirts of Dubai, property here can be purchased for
                much lower rates as compared to purchasing it in more populated
                areas in Dubai..
              </p>

              <p className="m-0">Lifestyle in Dubai</p>
              <p>
                Most people moving to Dubai look forward to enjoying its
                year-round sunny weather. So, it's no surprise that the beach is
                the most popular place to be when one wants to have fun and
                relax. Accordingly, one of the best beaches in Dubai is Jumeirah
                Beach. For those who want to stay out of the sun and still have
                a great time, they should visit Dubai Mall; it has an ice rink,
                indoor aquarium and over 200 restaurants to choose from. One
                unique place where one can enjoy the weekend with the family is
                Wild Wadi Waterpark which is located in the vicinity of
                Jumeirah. Apart from all this, there are several bars, clubs and
                restaurants to choose from in Dubai.
              </p>

              <p className="m-0">Sale Price Trends for Properties in Dubai</p>
              <p>
                For those interested in buying an apartment in Dubai, the
                typical studio flat will start from AED 200k. On the other hand,
                a typical 4-bed flat will sell for around AED 2M. When it comes
                to procuring a villa, a typical 2-bed villa will demand a price
                that will start from AED 890k. On the other hand, a 6-bed villa
                will sell for an average price of AED 6M. Moving on, depending
                on the size and location, the price for a typical office in
                Dubai can be anywhere from AED 120k to AED 10M. Considering
                these factors, it is apparent that there are plenty of options
                and price-points to suit investors of all backgrounds.
              </p>
            </div>
          </div>
          <div className="col-4">
            <img src="/assets/listing-burj.png" alt="" className="burj" />
          </div>
        </div>
      </div>

      <div className="blogs-footer">
        <div className="row main-container pt-5">
          <div className="col-lg-5 col-md-12 col-12">
            <div className="p-5 prompt-consultation-content">
              <h2>Prompt Consultation</h2>
              <p>Fill form below and our agent will contact you shortly</p>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                  <input type="text" placeholder="Your Name" />
                </div>
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                  <input type="text" placeholder="Your E-mail" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-5">
                  <input type="text" placeholder="Your Phone" />
                </div>
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-btn mt-5">
                  <Link>SUBMIT</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12 pt-3">
            <div className="prompt-consultation-img">
              <img alt="" src="/assets/Prompt-consultation.png" />
            </div>
            <div className="overlay_action"></div>
          </div>
        </div>

        <div className="row main-container mt-5">
          <div className="col-12 p-0">
            <hr style={{ border: "1px solid #d0ae83" }} />
          </div>
        </div>

        <div className="mt-2">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default PropertyListing;
