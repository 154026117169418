import React from 'react';
import "./AboutUs.css";
import { Link } from 'react-router-dom';
// import home from '../../../public/assets/home.png';
// import message from '../../../public/assets/message.png';
// import home2 from '../../../public/assets/home2.png';
// import icon2 from '../../../public/assets/icon2.png';
// import stock from '../../../public/assets/stock.png';
// import home3 from '../../../public/assets/home3.png';
// import dollar from '../../assets/dollar.png';
// import star from '../../../public/assets/star.png';
// import request from '../../../public/assets/request.png';
// import menu from '../../../public/assets/menu.png';
// import { Link } from "react-router-dom";
// import { IoSearch } from "react-icons/io5";
import { BiHomeAlt2 } from 'react-icons/bi';
import Footer from '../../Components/Footer/Footer';
import Navbar from '../../Components/Navbar/Navbar';

function AboutUs() {

    const aboutCard = [
        {
            icon: "/assets/home.png",
            heading: "Homebuyer Consultant",
            desc: "We offer a comprehensive and user-friendly service for home buyers in Dubai. Even if you are not currently in Dubai, we will assist you in finding an apartment or villa that perfectly suits your needs.",
        },
        {
            icon: "/assets/message.png",
            heading: "First-Time Buyer Advisory",
            desc: "Our assistance service supports first-time home buyers in purchasing a property. This includes a variety of programs and financial options specifically designed for people new to the housing market.",
        },
        {
            icon: "/assets/home2.png",
            heading: "Homeseller Consultant",
            desc: "We provide complete assistance in selling your home, apartment, or villa in Dubai. With our streamlined procedures, we will assist you in the marketing of your property as well.",
        },
        {
            icon: "/assets/icon2.png",
            heading: "Relocation Assistance",
            desc: "We provide expert guidance and comprehensive assistance throughout the entire relocation process, including finding a suitable new home, coordinating the move, and facilitating the sale of your current property.",
        },
        {
            icon: "/assets/stock.png",
            heading: "Off-Plan Property Investment",
            desc: "We understand that buying a new project can be challenging sometimes. Therefore, we provide full assistance in acquiring newly constructed homes that have never been lived in before, ensuring a seamless and successful investment experience.",
        },
        {
            icon: "/assets/home3.png",
            heading: "Home Inspection",
            desc: "We provide a comprehensive home inspection service that pays attention to detail. Our primary objective is to deliver a thorough and concise report to our clients, ensuring it is easily understandable.",
        },
        {
            icon: "/assets/dollar.png",
            heading: "Property Valuation",
            desc: "You can obtain the correct valuation of apartments and villas right away with the assistance of our property experts. We will make sure that your investment will be worthwhile.",
        },
        {
            icon: "/assets/star.png",
            heading: "Luxury Properties",
            desc: "We will assist you in purchasing luxury apartments that have high-end specifications.	Our team of experts will assist you throughout the buying and selling process..",
        },
        {
            icon: "/assets/request.png",
            heading: "Premium Assistance for Foreign Buyers",
            desc: "Purchase an apartment abroad and visit to see it for yourself. If you are a foreigner, we will warmly welcome you at the airport, guide you through the desired apartments and villas, and provide accommodation in Dubai.",
        },
        {
            icon: "/assets/menu.png",
            heading: "Other Services",
            desc: "If you are interested in inquiring about other services, please contact us below or call our office directly.",
        },


    ]


    return (
        <>
            <Navbar />

            <header className="about-us-header ">
                <div className="about-us-liner">
                    <div className="about-us-main  w-100 d-flex justify-content-center ">
                        <div className="About-us-section mb-5 mt-5 ">

                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-8 col-md-12 col-12">
                                        <div className="left-arrow mt-4">
                                            <span>&#x2190; Go To Back</span>
                                        </div>

                                        <h1>
                                            About us
                                        </h1>
                                        <p>
                                            At Muller and Co. we offer you the extensive collection of appealing properties available in Dubai with a personalized service.
                                            Accompanied by solely focusing on providing the very best property advice and service in the region, we support you at every stage, from the initial decision-making, and the process of your acquisition to its completion down to the smallest detail.
                                            We are here to make the process as smooth and stress-free as possible, based on our excellent knowledge of the field, trust, and professionalism.

                                        </p>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-12">
                                        <div>
                                            <img src="/assets/Mask-group.png" className="about-us-image" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </header >

            <div className="about-us-center-block">
                <div className="container">
                    <div class="row p-5 ">
                        <div className="col-lg-6 col-md-12 col-12 ">
                            <div className='about-us-center-box border p-4'>
                                <div className='about-us-center-block-heading'>
                                    <img src='/assets/leaderboard-star.png' alt='' />
                                    <h4>
                                        Our Mission
                                    </h4>
                                </div>
                                <div className='about-us-center-block-discription'>
                                    <p>
                                        At Muller & Co, our mission is to provide exceptional real estate solutions in Dubai.
                                        With a client-centric approach, we are committed to assisting home buyers, first-time
                                        buyers, and sellers with comprehensive services tailored to their needs. With a focus
                                        on affordable and premium properties and a commitment to delivering exceptional service,
                                        we aim to exceed client expectations and establish long-lasting relationships in the
                                        real estate industry.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 col-md-12 col-12 h-75">
                            <div className='about-us-center-box border p-4'>
                                <div className='about-us-center-block-heading'>
                                    <img src='/assets/eye-bg-icon.png' alt='' />
                                    <h4>
                                        Our Vision
                                    </h4>
                                </div>
                                <div className='about-us-center-block-discription'>
                                    <p>Our vision is to be the leading client-centric real estate company in Dubai,
                                        renowned for its exceptional service and expertise.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="main-container">
                <div className="About-us-third-box mt-5">
                    <h4>
                        Our Services
                    </h4>
                    <p>
                        Exceptional Real Estate Solutions in Dubai
                    </p>
                </div>
                <div class="row">
                    {aboutCard.map((data, i) => (
                        <div class="col-lg-3 col-md-6 col-12 mt-4">
                            <div className='about-us-first-card  border rounded p-4'>
                                <img src={data.icon} alt="" />
                                <h6>
                                    {data.heading}
                                </h6>
                                <p>
                                    {data.desc}
                                </p>
                            </div>
                        </div>
                    ))}


                </div>
            </div>



            <div className="blogs-footer">
                <div className="row main-container pt-5">
                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="p-5 prompt-consultation-content">
                            <h2>Prompt Consultation</h2>
                            <p>Fill form below and our agent will contact you shortly</p>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                                    <input type="text" placeholder="Your Name" />
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                                    <input type="text" placeholder="Your E-mail" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-5">
                                    <input type="text" placeholder="Your Phone" />
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-btn mt-5">
                                    <Link>SUBMIT</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 pt-3">
                        <div className="prompt-consultation-img">
                            <img alt="" src="/assets/Prompt-consultation.png" />
                        </div>
                        <div className="overlay_action"></div>
                    </div>
                </div>

                <div className="row main-container mt-5">
                    <div className="col-12 p-0">
                        <hr style={{ border: "1px solid #d0ae83" }} />
                    </div>
                </div>

                <div className="mt-2">
                    <Footer />
                </div>
            </div>


        </>
    )
}

export default AboutUs
