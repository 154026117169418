import React from 'react';
import "./InstantValuation.css";
import { Link } from 'react-router-dom';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
// import hotel from '../../assets/InstantValuation/hotel.png'
// import person1 from '../../assets/InstantValuation/person1.png'
// import person2 from '../../assets/InstantValuation/person2.png'
// import person3 from '../../assets/InstantValuation/person3.png'



function InstantValuation() {
    return (
        <>
            <Navbar />
            <div className="row instant-valuation-page align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12 instant-valuation-img">
                    <img src="/assets/valuation-hotel.png" alt="" />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 ">
                    <div className="instant-valuation-content">

                        <h2>Request a free property valuation</h2>
                        <p>Modified? Unique? Remortgaging? Considering renting or selling later? Or just curious? At haus & haus we understand that getting a no obligation valuation on your properties worth can help you make better informed decisions. Give us a try and remove the unanswered questions.</p>

                        <select name="" id="" style={{ width: "100%" }}>
                            <option >SELECT TYPE OF CATEGORY </option>

                        </select>

                        <div className="row mt-1 mb-4 ">
                            <div className="col-6 mt-3">
                                <input type="text" placeholder='NAME' />
                            </div>
                            <div className="col-6 mt-3">
                                <input type="text" placeholder='EMAIL' />
                            </div>
                            <div className="col-6 mt-3">
                                <input type="text" placeholder='CODE' />
                            </div>
                            <div className="col-6 mt-3">
                                <input type="text" placeholder='TELEPHONE' />
                            </div>
                            <div className="col-6 mt-3">
                                <input type="text" placeholder='ENTER YOUR BUILDING NUMBER' />
                            </div>
                            <div className="col-6 mt-3">
                                <input type="text" placeholder='ENTER YOUR ADDRESS' />
                            </div>
                        </div>
                        <textarea placeholder="YOUR MESSAGE HERE..." id="" cols="130" rows="7"></textarea>

                        <p>
                            <i>This site is protected by reCAPTCHA and the Google
                                <Link href="">Privacy Policy</Link> and <a href="">Terms of Service</a> apply.</i>
                        </p>


                        <div className="row mb-4">
                            <div className="col-6">
                                <button className='submit-btn'>SUBMIT</button>
                            </div>
                            <div className="col-6">
                                <p><i>by clicking submit I agree to our <a href="">terms & conditions</a> </i></p>
                            </div>
                        </div>
                        <h2>We're here to help you</h2>

                        <div className="row align-items-center images">
                            <div className="col-4">
                                <div className="">
                                    <img src="/assets/person1.png" alt="" className='img1' />
                                    <img src="/assets/person2.png" alt="" className='img2' />
                                    <img src="/assets/person3.png" alt="" className='img3' />
                                </div>
                            </div>
                            <div className="col-6">
                                <p>Call Our Sales experts on:</p>
                                <p>+1234567890</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



            <div className="instant-valuation-footer">
                <div className="row main-container pt-5">
                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="p-5">
                            <div className="prompt-consultation-content">
                                <h2>Prompt Consultation</h2>
                                <p>Fill form below and our agent will contact you shortly</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12  mt-4">
                                    <div className='prompt-consultation-inputs'>
                                        <input type="text" placeholder="Your Name" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 mt-4">
                                    <div className='prompt-consultation-inputs'>
                                        <input type="text" placeholder="Your E-mail" />
                                    </div>


                                </div>
                                <div className="col-lg-6 col-md-12 col-12  mt-5">
                                    <div className='prompt-consultation-inputs'>
                                        <input type="text" placeholder="Your Phone" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12  mt-5">
                                    <div className='prompt-consultation-btn'>
                                        <Link>SUBMIT</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 pt-3">
                        <div className="prompt-consultation-img">
                            <img alt="" src="/assets/Prompt-consultation.png" />
                        </div>
                        <div className="overlay_action"></div>
                    </div>
                </div>

                <div className="row main-container mt-5">
                    <div className="col-12 p-0">
                        <hr style={{ border: "1px solid #d0ae83" }} />
                    </div>
                </div>

                <div className="mt-2">
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default InstantValuation
