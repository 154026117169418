import React from 'react';
import "./ContactUs.css";
import Navbar from "../../Components/Navbar/Navbar";
import { Link } from "react-router-dom";
import Footer from '../../Components/Footer/Footer';
import { BsArrowLeft, BsFillTelephoneFill, BsWhatsapp } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

function ContactUs() {
  return (
    <div className="contact-background">
      <Navbar />
      <header className="contact-header">
        <div className="contact-header-content-wrapper">
          <div className="contact-header-content-bg">
            <div className="row contact-header-content">
              <div className="col-lg-5 contact-left-content">

                <div className="contact-back-btn">
                  <Link> <BsArrowLeft style={{ marginRight: "5px", fontSize: "25px" }} /> Go To Back  </Link>
                </div>
                <h2>Get in touch with us today!</h2>
                <p>If you are looking for a real estate agency in Dubai that can help you find
                  the perfect property for your needs, then contact us today.
                  We would be happy to answer any questions you have and help
                  you get started on your property search.</p>

                <div className='contact-left-content-btnwrapper'>
                  <div className="contact-call-btn">
                    <Link> <BsFillTelephoneFill style={{ marginRight: "15px", fontSize: "12px" }} /><span>Call</span></Link>
                  </div>
                  <div className="contact-whatsapp-btn">
                    <Link> <BsWhatsapp style={{ marginRight: "15px", fontSize: "12px" }} /> Whatsapp  </Link>
                  </div>
                  <div className="contact-email-btn">
                    <Link> <MdEmail style={{ marginRight: "15px", fontSize: "12px" }} />E-Mail </Link>
                  </div>
                </div>


              </div>
              <div className="col-lg-3">
                <div className="contact-img-wrapper">
                  <img alt="" src="/assets/contact-img1.png" />
                </div>
              </div>

              <div className="col-lg-4 contact-right-content">
                <div className="contact-name-wrapper">
                  <BsFillTelephoneFill
                    style={{
                      background: "#d0ae83", padding: "15px",
                      fontSize: "50px", color: "#0c3b3a"
                    }}
                  />
                  <h4>Contact Us</h4>
                </div>
                <div className="contact-company-name">
                  <h2>Müller & Co</h2>
                </div>

                <div className="contact-address-wrapper">
                  <img alt="" src="/assets/country-locationIcon.png" />
                  <p>Dubai/ United Arab Emirates</p>
                </div>

                <div className="contact-address-wrapper mt-3">
                  <img alt="" src="/assets/office-no.png" />
                  <p>Office Number: 1601</p>
                </div>
                <div className="contact-address-wrapper">
                  <img alt="" src="/assets/building-icon.png" />
                  <p>Building Name: Iris Bay Tower</p>
                </div>
                <div className="contact-address-wrapper">
                  <img alt="" src="/assets/street-locationIcon.png" />
                  <p>Street Name: 145 Al Mustaqbal</p>
                </div>
                <div className="contact-address-wrapper">
                  <img alt="" src="/assets/area-locationIcon.png" />
                  <p>Area: Business Bay</p>
                </div>
                <div className="contact-address-wrapper">
                  <img alt="" src="/assets/city-locationIcon.png" />
                  <p>City: Dubai</p>
                </div>
                <div className="contact-address-wrapper">
                  <img alt="" src="/assets/country-locationIcon.png" />
                  <p>Country: United Arab Emirates</p>
                </div>
                <div className="contact-address-wrapper mt-3">
                  <img alt="" src="/assets/phone-icon.png" />
                  <p>Phone: +971 43544005</p>
                </div>
                <div className="contact-address-wrapper">
                  <img alt="" src="/assets/mobile-icon.png" />
                  <p>Mobile: +971 505573855</p>
                </div>


              </div>
            </div>
          </div>
        </div>

      </header>


      <div className="main-container">
        {/* <div className="row">
          <div className="col-12 contact-map-img ">
            <img alt="" src="/assets/contact-map.png" />
          </div>
        </div> */}
        <div className="row mt-5 mb-5">
          <div className="col-lg-12 col-md-12 col-12 mt-5">
            <div className="mapouter w-100">
              <div className="gmap_canvas  location-map">
                <iframe id="gmap_canvas"
                  src="https://maps.google.com/maps?q=dubai&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                </iframe>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div className="mt-2">
        <Footer />
      </div>


    </div>
  )
}

export default ContactUs
