import React from 'react';
import "./Investment.css";
import { BiSearch } from "react-icons/bi";
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';



function Investment() {


    const investmentData = [
        {
            name: "Sobha One",
            description: "Enjoy the luxury apartment with views of the Burj Khalifa,Creek Tower to the northeast, and Downtown to the west.",
            image: "/assets/hotel.png"
        },
        {
            name: "Sobha Two",
            description: "Enjoy the luxury apartment with views of the Burj Khalifa,Creek Tower to the northeast, and Downtown to the west.",
            image: "/assets/hotel.png"
        },

        {
            name: "Sobha Three",
            description: "Enjoy the luxury apartment with views of the Burj Khalifa,Creek Tower to the northeast, and Downtown to the west.",
            image: "/assets/hotel.png"
        },

        {
            name: "Sobha Four",
            description: "Enjoy the luxury apartment with views of the Burj Khalifa,Creek Tower to the northeast, and Downtown to the west.",
            image: "/assets/hotel.png"
        },


    ];


    return (
        <>
            <Navbar />
            <div className="offplan-header">
                <h1>Sobha One</h1>
                <p>
                    Enjoy the luxury apartment with views of the Burj Khalifa, Creek Tower
                    to the northeast, and Downtown to the west.
                </p>
                <button className="read-more"> Read More &#8594;</button>
            </div>
            <div className="main-container">
                <div className="offplan-form row">
                    <div className="col-lg-4 col-md-6 col-sm-12 ">
                        <label htmlFor="">Area</label>
                        <div className="select_box">
                            <select name="" id="">
                                <option value="">Select Location</option>
                                <option value="">Select Location</option>
                                <option value="">Select Location</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 vl">
                        <label htmlFor="">Property Type</label>
                        <div className="select_box">
                            <select name="" id="">
                                <option value="">Choose Property Type</option>
                                <option value="">Choose Property Type</option>
                                <option value="">Choose Property Type</option>
                            </select>
                        </div>
                        {/* <div class="vl"></div> */}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 row vl">
                        <div className="col-9">
                            <label htmlFor="">Price Range</label>
                            <div className="select_box ">
                                <select name="" id="">
                                    <option value="">Choose a Range </option>
                                    <option value="">Choose a Range</option>
                                    <option value="">Choose a Range</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-3">


                            <button className="search-btnn">
                                <BiSearch />
                            </button>
                        </div>
                        {/* <div class="vl"></div> */}
                    </div>
                </div>

                <div className="row mt-5">
                    {investmentData.map((data, i) => (
                        <div className="col-lg-6 col-md-12 col-12 mt-5">
                              <div className="offplan-card">
                            <div className="row">
                                <div
                                    style={{ backgroundImage: `url(${data.image})` }}
                                    className="offplan-card-img col-lg-6 col-md-12 col-12"></div>
                                <div className="offplan-card-content col-lg-6 col-md-12 col-12">
                                    <h5>{data.name}</h5>
                                    <p>
                                        {data.description}

                                    </p>
                                    <button className="read-more"> Read More &#8594;</button>
                                </div>
                            </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>



            <div className="investment-footer">
            <div className="row main-container pt-5">
                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="p-5">
                            <div className="prompt-consultation-content">
                                <h2>Prompt Consultation</h2>
                                <p>Fill form below and our agent will contact you shortly</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12  mt-4">
                                    <div className='prompt-consultation-inputs'>
                                        <input type="text" placeholder="Your Name" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 mt-4">
                                    <div className='prompt-consultation-inputs'>
                                        <input type="text" placeholder="Your E-mail" />
                                    </div>


                                </div>
                                <div className="col-lg-6 col-md-12 col-12  mt-5">
                                    <div className='prompt-consultation-inputs'>
                                        <input type="text" placeholder="Your Phone" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12  mt-5">
                                    <div className='prompt-consultation-btn'>
                                        <Link>SUBMIT</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 pt-3">
                        <div className="prompt-consultation-img">
                            <img alt="" src="/assets/Prompt-consultation.png" />
                        </div>
                        <div className="overlay_action"></div>
                    </div>
                </div>

                <div className="row main-container mt-5">
                    <div className="col-12 p-0">
                        <hr style={{ border: "1px solid #d0ae83" }} />
                    </div>
                </div>

                <div className="mt-2">
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Investment
