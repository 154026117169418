import React from 'react';
import "./BlogDetail.css";
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';

function BlogDetail() {
    return (
        <>
            <Navbar />

            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12  ">
                    <img src={'/assets/blog-detail-1.png'} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="blog-click-top-section">

                        <h2>Lorem ipsum
                            diler sit amet</h2>
                        <hr />
                        <div className="d-flex gap-3">
                            <img src="/assets/calender-icon.svg" alt="" />
                            <p>

                                May 18, 2023
                            </p>
                            <img src="/assets/directory-icon.svg" alt="" />
                            <span>
                                Stories, Tips
                            </span>

                        </div>

                    </div>

                </div>
            </div>
            <div className="main-container">
                <div class="row mt-5">
                    <div class="col-6 blog-click-main-box mt-3 ">
                        <img src="/assets/blog-detail2.png" />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                    </div>
                    <div className="col-6">
                        <h2 className="click-blog-heading">Recent Post</h2>
                        <div className="recent-post-content-wrapper  ">

                            <div className="grey-box"></div>
                            <div className="recent-post-content">
                                <h3 >Travel Stories for Now and the Future</h3>
                                <p>14 Dec 2022</p>
                                <img src="" alt="" />
                            </div>

                        </div>
                        <div className="recent-post-content-wrapper ">
                            <div className="grey-box"></div>
                            <div className="recent-post-content">
                                <h3>Travel Stories for Now and the Future</h3>
                                <p>14 Dec 2022</p>
                            </div>
                        </div>
                        <div className="recent-post-content-wrapper">
                            <div className="grey-box"></div>
                            <div className="recent-post-content">
                                <h3>Travel Stories for Now and the Future</h3>
                                <p>14 Dec 2022</p>
                            </div>
                        </div>

                    </div>
                    <div className="blog-click-second-section">
                        <h2>
                            Rice Terraces, Tegallalang
                        </h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.
                        </p>
                        <img src="/assets/blog-detail3.png" />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        </p>

                    </div>
                    <div className="blog-click-third-box ">

                        <p>
                            tags
                        </p>
                        <h6>
                            Destintion, Travel
                        </h6>
                        <span>
                            Share This :
                        </span>
                        <div>
                            <img src="/assets/linkedin-black.svg" alt="" />
                            <img src="/assets/twitter-black.svg" alt="" />
                            <img src="/assets/facebook-icon.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>



            <div className="blogs-footer">
                <div className="row main-container pt-5">
                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="p-5 prompt-consultation-content">
                            <h2>Prompt Consultation</h2>
                            <p>Fill form below and our agent will contact you shortly</p>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                                    <input type="text" placeholder="Your Name" />
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                                    <input type="text" placeholder="Your E-mail" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-5">
                                    <input type="text" placeholder="Your Phone" />
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-btn mt-5">
                                    <Link>SUBMIT</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 pt-3">
                        <div className="prompt-consultation-img">
                            <img alt="" src="/assets/Prompt-consultation.png" />
                        </div>
                        <div className="overlay_action"></div>
                    </div>
                </div>

                <div className="row main-container mt-5">
                    <div className="col-12 p-0">
                        <hr style={{ border: "1px solid #d0ae83" }} />
                    </div>
                </div>

                <div className="mt-2">
                    <Footer />
                </div>
            </div>

        </>
    )
}

export default BlogDetail
