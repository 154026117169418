import React, { useState } from 'react';
import "./Careers.css";
import { MdOutlineTimer } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';


function Careers() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Navbar />
            <div className="row career-top-card-wrapper">
                <div className="col-lg-5 mt-5">
                    <div className="career-top-card">
                        <h6>
                            Career at
                        </h6>
                        <span> Müller & Co Real Estate</span>
                        <p>
                            If you are looking for a company that values and fuels career growth, then Mueller and Co is the right place for you. We offer a supportive work environment, competitive salaries and benefits, and a variety of opportunities for career growth.
                        </p>
                        <button onClick={handleShow}>APPLY NOW</button>
                    </div>
                </div>
                <div className="col-lg-7 career-top-card-image mt-5 p-0">
                    <img src="/assets/careers-image1.png" alt="" />
                </div>
            </div>
            <Modal className='career-apply-modal' show={show} onHide={handleClose}>
                <div className='career-apply-modal-heading'>
                    <h4>
                        Apply Now
                    </h4>
                </div>
                <div className="row apply-now-from p-5">
                    <div className="col-lg-4 col-md-6 col-12  mb-5 ">
                        <input type="text" placeholder="First Name" />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-5">
                        <input type="text" placeholder="Last name" />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-5">
                        <input type="text" placeholder="Email" />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-5">
                        <input type="text" placeholder="Code" />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-5">
                        <input type="text" placeholder="Number" />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-5">
                        <input type="text" placeholder="Upload your CV" />
                    </div>
                    <div class="d-flex justify-content-center col-lg-12 col-md-6 col-12 mb-5">
                        <button onClick={handleClose} class="btn career-submit-btn">Submit</button>
                    </div>
                </div>

            </Modal>


            <div className="main-container">
                <div className="career-card-center-section mb-5">
                    <div className="row">
                        <div className="col-lg-12 career-center-card">
                            <h1>
                                Current Job Openings at Muller and Co:
                            </h1>
                        </div>
                    </div>
                    <div className="row career-card-detail p-3 m-5">
                        <div className="col-lg-3">
                            <h4>Real Estate Agent</h4>
                        </div>
                        <div className="col-lg-3 d-flex align-items-center gap-3">
                            <MdOutlineTimer />
                            <p className="m-0">Full time</p>
                        </div>
                        <div className="col-lg-3 d-flex align-items-center gap-3">
                            <MdLocationOn />
                            <p className="m-0">Dubai</p>
                        </div>
                        <div className="col-lg-3 d-flex align-items-center gap-3">
                            <div>
                                <button className="view-all-job-btn"> View Job Detail &#x2192;</button>
                            </div>
                        </div>
                    </div>
                    <div className="row career-card-detail p-3 m-5">
                        <div className="col-lg-3">
                            <h4>Real Estate Agent</h4>
                        </div>
                        <div className="col-lg-3 d-flex align-items-center gap-3">

                            <MdOutlineTimer />

                            <p className="m-0">Full time</p>
                        </div>
                        <div className="col-lg-3 d-flex align-items-center gap-3">

                            <MdLocationOn />

                            <p className="m-0">Dubai</p>
                        </div>
                        <div className="col-lg-3 d-flex align-items-center  gap-3">
                            <div>
                                <button className="view-all-job-btn m-0"> View Job Detail &#x2192;</button>
                            </div>
                        </div>
                    </div>
                    <div className="row career-card-detail p-3 m-5">
                        <div className="col-lg-3">
                            <h4>Real Estate Agent</h4>
                        </div>
                        <div className="col-lg-3 d-flex align-items-center gap-3">

                            <MdOutlineTimer />

                            <p className="m-0">Full time</p>
                        </div>
                        <div className="col-lg-3 d-flex align-items-center gap-3">

                            <MdLocationOn />

                            <p className="m-0">Dubai</p>
                        </div>
                        <div className="col-lg-3 d-flex align-items-center gap-3">
                            <div>
                                <button className="view-all-job-btn m-0"> View Job Detail &#x2192;</button>
                            </div>
                        </div>
                    </div>
                    <div className=" d-flex align-items-center justify-content-center">
                        <button className="current-job-section-btn mb-5">
                            View all &#x2192;
                        </button>
                    </div>
                </div>
            </div>

            <div className="row career-join-us-section-wrapper">
                <div className="col-lg-5 col-md-12 col-12 career-join-us-section">
                    <div className="join-us-heading">
                        <h2 className="">
                            Why Join Us?
                        </h2>
                    </div>
                    <div className="mt-5">
                        <li>
                            A work culture that encourages active participation and collaboration.
                        </li>
                        <li>
                            Competitive pay, additional benefits, and performance-based bonuses.
                        </li>
                        <li>
                            Opportunities for training and career advancement.
                        </li>
                        <li>
                            Interactive team-building events and activities.
                        </li>
                        <li>
                            A management team that offers reliable support and encouragement.
                        </li>
                        <p className="mt-5 fw-bold">

                            Join us today and start your journey to a successful career

                        </p>
                    </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12 p-0 career-join-us-section-image">
                    <img src="/assets/careers-image2.png" alt="" />
                </div>
            </div>


            <div className="careers-footer">
                <div className="row main-container pt-5">
                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="p-5 prompt-consultation-content">
                            <h2>Prompt Consultation</h2>
                            <p>Fill form below and our agent will contact you shortly</p>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                                    <input type="text" placeholder="Your Name" />
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                                    <input type="text" placeholder="Your E-mail" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-5">
                                    <input type="text" placeholder="Your Phone" />
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-btn mt-5">
                                    <Link>SUBMIT</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 pt-3">
                        <div className="prompt-consultation-img">
                            <img alt="" src="/assets/Prompt-consultation.png" />
                        </div>
                        <div className="overlay_action"></div>
                    </div>
                </div>

                <div className="row main-container mt-5">
                    <div className="col-12 p-0">
                        <hr style={{ border: "1px solid #d0ae83" }} />
                    </div>
                </div>

                <div className="mt-2">
                    <Footer />
                </div>
            </div>


        </>
    )
}

export default Careers
