import React from "react";
import "./PropertyFloorPlan.css";
import Navbar from "../../Components/Navbar/Navbar";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsArrowLeft, BsBuildingsFill, BsFillImageFill } from "react-icons/bs";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";

function PropertyFloorPlan() {
  const floorplan = [
    {
      icon: "/assets/image1.png",
      text: "show",
      icon2: "/assets/eye-icon.png",
      title: "Tower A",
      title2: "4 Bedrooms",
      title3: "Type A",
      title4: "1099.32 sq Ft",
      title5: "Appartment",
    },
    {
      icon: "/assets/image1.png",
      text: "show",
      icon2: "/assets/eye-icon.png",
      title: "Tower A",
      title2: "4 Bedrooms",
      title3: "Type A",
      title4: "1099.32 sq Ft",
      title5: "Appartment",
    },
    {
      icon: "/assets/image1.png",
      text: "show",
      icon2: "/assets/eye-icon.png",
      title: "Tower A",
      title2: "4 Bedrooms",
      title3: "Type A",
      title4: "1099.32 sq Ft",
      title5: "Appartment",
    },
    {
      icon: "/assets/image1.png",
      text: "show",
      icon2: "/assets/eye-icon.png",
      title: "Tower A",
      title2: "4 Bedrooms",
      title3: "Type A",
      title4: "1099.32 sq Ft",
      title5: "Appartment",
    },
    {
      icon: "/assets/image1.png",
      text: "show",
      icon2: "/assets/eye-icon.png",
      title: "Tower A",
      title2: "4 Bedrooms",
      title3: "Type A",
      title4: "1099.32 sq Ft",
      title5: "Appartment",
    },
    {
      icon: "/assets/image1.png",
      text: "show",
      icon2: "/assets/eye-icon.png",
      title: "Tower A",
      title2: "4 Bedrooms",
      title3: "Type A",
      title4: "1099.32 sq Ft",
      title5: "Appartment",
    },
    {
      icon: "/assets/image1.png",
      text: "show",
      icon2: "/assets/eye-icon.png",
      title: "Tower A",
      title2: "4 Bedrooms",
      title3: "Type A",
      title4: "1099.32 sq Ft",
      title5: "Appartment",
    },
    {
      icon: "/assets/image1.png",
      text: "show",
      icon2: "/assets/eye-icon.png",
      title: "Tower A",
      title2: "4 Bedrooms",
      title3: "Type A",
      title4: "1099.32 sq Ft",
      title5: "Appartment",
    },
    {
      icon: "/assets/image1.png",
      text: "show",
      icon2: "/assets/eye-icon.png",
      title: "Tower A",
      title2: "4 Bedrooms",
      title3: "Type A",
      title4: "1099.32 sq Ft",
      title5: "Appartment",
    },
    {
      icon: "/assets/image1.png",
      text: "show",
      icon2: "/assets/eye-icon.png",
      title: "Tower A",
      title2: "4 Bedrooms",
      title3: "Type A",
      title4: "1099.32 sq Ft",
      title5: "Appartment",
    },
  ];

  return (
    <>
      <Navbar />
      <div className="main-container">
        <div className="project-card-top-section">
          <button className="project-card-top-btn mt-5">
            &#x2190; GO TO BACK
          </button>
          <div>
            <h2>Sobha One Floor Plans</h2>
            <p>
              Developer offers a range of meticulously designed floor plans to
              suit various preferences. From spacious studios to elegant one and
              two-bedroom apartments, each layout is a testament to modern
              comfort and style. Developer offers a range of meticulously
              designed floor plans to suit various preferences. From spacious
              studios to elegant one and two-bedroom apartments, each layout is
              a testament to modern comfort and style.
            </p>
          </div>
        </div>

        <div className="listing-page-form row mb-5">
          <div className="col-lg-3 col-md-4 col-sm-12 ">
            <label htmlFor="">Area</label>
            <div className="select_box">
              <select name="" id="">
                <option value="">Select Location</option>
                <option value="">Select Location</option>
                <option value="">Select Location</option>
              </select>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 listing-vl">
            <label htmlFor="">Property Type</label>
            <div className="select_box">
              <select name="" id="">
                <option value="">Choose Property Type</option>
                <option value="">Choose Property Type</option>
                <option value="">Choose Property Type</option>
              </select>
            </div>
            {/* <div class="vl"></div> */}
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 row listing-vl">
            <div className="col-9">
              <label htmlFor="">Developer</label>
              <div className="select_box ">
                <select name="" id="">
                  <option value="">Choose Developer </option>
                  <option value="">Choose a Developer</option>
                  <option value="">Choose a Developer</option>
                </select>
              </div>
            </div>
            <div className="col-3"></div>
            {/* <div class="vl"></div> */}
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 row listing-vl">
            <div className="col-9">
              <label htmlFor="">Price Range</label>
              <div className="select_box ">
                <select name="" id="">
                  <option value="">Choose a Range </option>
                  <option value="">Choose a Range</option>
                  <option value="">Choose a Range</option>
                </select>
              </div>
            </div>
            <div className="col-3">
              <button className="listing-page-search-btnn">
                <BiSearch />
              </button>
            </div>
            {/* <div class="vl"></div> */}
          </div>
        </div>

        <div>
          <>
            <table class="table table-bordered mb-5">
              <thead>
                <tr>
                  <th scope="col">Floor Plan</th>
                  <th scope="col">Category</th>
                  <th scope="col">Unit Type</th>
                  <th scope="col">Floor Details</th>
                  <th scope="col">Sizes</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>

              {floorplan.map((data, i) => (
                <tbody>
                  <tr>
                    <td scope="row">
                      <img src={data.icon} alt="" />
                      <h6 className="m-0">{data.text}</h6>
                      <img src={data.icon2} alt="" style={{ width: "20px" }} />
                    </td>
                    <td>{data.title}</td>
                    <td>{data.title2}</td>
                    <td>{data.title3}</td>
                    <td>{data.title4}</td>
                    <td>{data.title5}</td>
                  </tr>
                </tbody>
              ))}
            </table>
          </>

          <div>
            <h4 className="text-center sobha-group-heading">
              Other projects of SOBHA Group
            </h4>
          </div>

          <div className="row mt-4 mb-5">
            {[1, 2, 3].map((data, i) => (
              <div className="col-lg-4">
                <div className="similar-property-card">
                  <div className="aed">
                    {" "}
                    <p>from AED 10,000,000</p>
                  </div>
                </div>
                <div className="mx-3">
                  <p className="fw-bold ">Tingah Elegant House</p>
                  <FaMapMarkerAlt className="me-1" /> Business Bay
                  <div className="row mt-2 bg-light p-2">
                    <div className="col">
                      <BsBuildingsFill className="me-1" />
                      Lorem ipsum
                    </div>
                    <div className="col">
                      <img src="/assets/give.png" alt="" className="me-1" /> Q1
                      2026
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div style={{display:'flex', justifyContent:'center'}}>
        <Link >
          <button className="bg-theme border-0 text-white p-2 px-4 rounded-pill">
            See More
          </button>
            </Link>
        </div>
      </div>

      <div className="buyproperty-footer">
        <div className="row main-container pt-5">
          <div className="col-lg-5 col-md-12 col-12">
            <div className="p-5 prompt-consultation-content">
              <h2>Prompt Consultation</h2>
              <p>Fill form below and our agent will contact you shortly</p>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                  <input type="text" placeholder="Your Name" />
                </div>
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                  <input type="text" placeholder="Your E-mail" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-5">
                  <input type="text" placeholder="Your Phone" />
                </div>
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-btn mt-5">
                  <Link>SUBMIT</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12 pt-3">
            <div className="prompt-consultation-img">
              <img alt="" src="/assets/Prompt-consultation.png" />
            </div>
            <div className="overlay_action"></div>
          </div>
        </div>

        <div className="row main-container mt-5">
          <div className="col-12 p-0">
            <hr style={{ border: "1px solid #d0ae83" }} />
          </div>
        </div>

        <div className="mt-2">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default PropertyFloorPlan;
