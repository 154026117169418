import React from "react";
import "./PropertyCarousal.css";
import "bootstrap/dist/css/bootstrap.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";


import { FaMapMarkerAlt } from "react-icons/fa";
import { BsFillTelephoneFill, BsWhatsapp, BsFillEnvelopeFill } from "react-icons/bs";
import { BiSolidBed, BiSolidBath } from "react-icons/bi";
import { MdArrowRightAlt } from "react-icons/md";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

import Carousel from "react-multi-carousel";





function PropertyCarousal() {
    function CustomNextArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <BsArrowRight className="custom-arrow-icon " />
            </div>
        );
    }

    function CustomPrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <div className="retreh">
                    <BsArrowLeft className="custom-arrow-icon " />
                </div>
            </div>
        );
    }
    const property = [
        {
            image: '/assets/offProperty-img2.png',
            name: "Abdur Rehman",
            location: "Bussiness Bay",
            apartmentName: "Apartment"
        },
        {
            image: '/assets/offProperty-img2.png',
            name: "Ahzaz",
            location: "Bussiness Bay",
            apartmentName: "Apartment"
        },
        {
            image: '/assets/offProperty-img2.png',
            name: "Saad",
            location: "Bussiness Bay",
            apartmentName: "Apartment"
        },
        {
            image: '/assets/offProperty-img2.png',
            name: "Ahzaz",
            location: "Bussiness Bay",
            apartmentName: "Apartment"
        },
    ];


    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 765,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },

        ]
    };


    return (
        <>
            <div className="">
                <Slider {...settings}>
                    {property.map((data, i) => (
                        <div
                            className="box"
                            key={i}
                            style={{ background: `url(${data.image})` }}
                        >
                            <p className="property-carousal-appartment">{data.apartmentName}</p>
                            <div className=" cardd">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div className="ms-3">
                                        <p className="m-0">{data.name}</p>
                                        <div className=" icon">
                                            <FaMapMarkerAlt /><p>{data.location}</p>
                                        </div>
                                    </div>

                                    <p className="property-carosal-price">AED 20,000,000</p>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between align-items-center ps-2">
                                    <BiSolidBed className="themeicon" /> 4
                                    <BiSolidBath className="themeicon" /> 2
                                    <BiSolidBath className="themeicon" /> 2132 sq.
                                    <div className="phone"><BsFillTelephoneFill className="themeicon" /></div>
                                    <BsWhatsapp className="themeicon" />
                                    <div className="email"><BsFillEnvelopeFill className="themeicon" /></div>
                                </div>
                            </div>
                        </div>
                    ))}


                </Slider>

                {/* <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {data.map((item, index) => {
            return (
              <div className="box" key={index}>
              <p className="appartment">Appartment</p>
              <div className=" cardd">
               <div className="d-flex justify-content-between align-items-center mb-3" >
                <div className="ms-3">
                  <p className="m-0">Ahzaz NAseer</p>
                  <div className=" icon">
                    <FaMapMarkerAlt/><p>Bussiness Bay</p>  
                  </div>
                </div>
                  <p className="aed">AED 20,000,000</p>  
               </div>
               <hr/>
               <div className="d-flex justify-content-between align-items-center ps-2">
                <BiSolidBed className="themeicon"/> 4
                <BiSolidBath className="themeicon"/> 2
                <BiSolidBath className="themeicon"/> 2132 sq.
                <div className="phone"><BsFillTelephoneFill className="themeicon"/></div>
               <BsWhatsapp className="themeicon"/>
                <div className="email"><BsFillEnvelopeFill className="themeicon"/></div>
               </div>
              </div>
            </div>
            );
          })}
        </Carousel> */}
            </div>
        </>
    );
}

export default PropertyCarousal; 
