import React from 'react';
import "./BuyerGuide.css";
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';

function BuyerGuide() {
    return (
        <>
            <Navbar />
            <div className="row buyer-guide-heading-wrapper">
                <div className="col-lg-6 col-md-12 col-12 mt-5">
                    <div className="buyer-guide-heading">
                        <h2>
                            Buyers Guide
                        </h2>
                        <span>
                            How to buy the perfect Dubai property
                        </span>
                        <p>
                            Whether you're looking for your next home in Dubai or a lucrative investment, this guide contains everything you need to know – from legal factors to recommendations from our property consultants.
                        </p>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <button className="properties-sale-btn">PROPERTIES FOR SALE</button>
                            </div>
                            <div className="col-lg-8 col-md-6 col-12">
                                <button className="buyer-guide-contact-btn">CONTACT US</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 mt-5 real-state-image p-0">
                    <img src="/assets/buyer-realestate.png" alt="" />
                </div>
            </div>


            <div className="main-container">
                <div className="row">
                    <div className="col-12 mt-5 mb-3 buyer-guide-paragraph">
                        <p>
                            A very attractive aspect of the Dubai property market is the fact that it remains
                            open to all overseas investors with no limitations or complicated requirements.
                            In fact, it is not necessary to be a UAE resident or to be physically present
                            during the purchase process.
                        </p>
                        <p>
                            All company documents must be translated into Arabic and they may vary depending on
                            the type of company. We recommend contacting a reputable real estate agency such
                            as haus & haus to support you with an exact list of requirements for your case.
                        </p>
                    </div>
                </div>
                <div className="row mt-5 mb-5">
                    <div className="col-lg-6 col-md-12 col-sm-12 buyer-guide-third-card">
                        <h4>
                            What you need
                        </h4>
                        <p>
                            What you need
                            The documentation needed will vary depending on whether you are buying as an individual or as a corporate entity and the payment method that you choose. <br />
                            <strong>  Individual buyer — </strong>To begin you will need to provide your original passport and Emirates ID. If you're not a UAE resident, you will only provide your passport as your identification. <br />
                            <strong>Company or corporate entity — </strong> Properties can also be purchased through UAE based companies. If this is your case, you will be requested to provide a list of documents including a Certificate of Incorporation, Board of Resolution, Power of Attorney for the designated signatory and the personal documents mentioned above.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 buyer-guide-third-card">
                        <h4>
                            What, when, and where to buy?
                        </h4>
                        <p>
                            What, when, and where to buy?
                            Before you start your search, it helps to know the type of property that you're looking for– a home for yourself, a rental property on a yearly basis or a holiday rental. Dubai offers plenty of options such as stylish apartments, spacious family villas, and modern townhouses. If you're still not sure about what you're seeking, that's fine as well – our experienced property and investment consultants can offer you informed recommendations based on what your goals are so you can begin viewing potential properties and make the best decisions along the way.Choosing a real estate agent with experience in Dubai communities will also make your process easier and smoother. Our area specialists are proficient in the city's major neighbourhoods – they will help you answer the 'where' part of the equation, providing expert advice and spotting lucrative opportunities tailored to your needs.
                        </p>
                    </div>
                </div>
            </div>

            <div className="row buyer-guide-heading-wrapper">
                <div className="col-lg-3 col-md-12 col-12 mt-5">
                    <button className="buyer-guide-help-btn">
                        HELP ME FIND A PROPERTY
                    </button>
                </div>
                <div className="col-lg-9 col-md-12 col-12 mt-5buyer-guide-house-image">
                    <img src="/assets/buyer-houseLoan.png" alt="" />
                </div>
            </div>

            <div className="main-container">
                <div className="row mt-5 mb-5">
                    <div className="col-lg-6 col-md-12 col-sm-12 buyer-guide-third-card">
                        <h4>
                            What you need
                        </h4>
                        <p>
                            What you need
                            The documentation needed will vary depending on whether you are buying as an individual or as a corporate entity and the payment method that you choose. <br />
                            <strong>  Individual buyer — </strong>To begin you will need to provide your original passport and Emirates ID. If you're not a UAE resident, you will only provide your passport as your identification. <br />
                            <strong>Company or corporate entity — </strong> Properties can also be purchased through UAE based companies. If this is your case, you will be requested to provide a list of documents including a Certificate of Incorporation, Board of Resolution, Power of Attorney for the designated signatory and the personal documents mentioned above.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 buyer-guide-third-card">
                        <h4>
                            What, when, and where to buy?
                        </h4>
                        <p>
                            What, when, and where to buy?
                            Before you start your search, it helps to know the type of property that you're looking for– a home for yourself, a rental property on a yearly basis or a holiday rental. Dubai offers plenty of options such as stylish apartments, spacious family villas, and modern townhouses. If you're still not sure about what you're seeking, that's fine as well – our experienced property and investment consultants can offer you informed recommendations based on what your goals are so you can begin viewing potential properties and make the best decisions along the way.Choosing a real estate agent with experience in Dubai communities will also make your process easier and smoother. Our area specialists are proficient in the city's major neighbourhoods – they will help you answer the 'where' part of the equation, providing expert advice and spotting lucrative opportunities tailored to your needs.
                        </p>
                    </div>
                </div>
            </div>


            <div className="buyer-guide-footer">
                <div className="row main-container pt-5">
                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="p-5 prompt-consultation-content">
                            <h2>Prompt Consultation</h2>
                            <p>Fill form below and our agent will contact you shortly</p>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                                    <input type="text" placeholder="Your Name" />
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                                    <input type="text" placeholder="Your E-mail" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-5">
                                    <input type="text" placeholder="Your Phone" />
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-btn mt-5">
                                    <Link>SUBMIT</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 pt-3">
                        <div className="prompt-consultation-img">
                            <img alt="" src="/assets/Prompt-consultation.png" />
                        </div>
                        <div className="overlay_action"></div>
                    </div>
                </div>

                <div className="row main-container mt-5">
                    <div className="col-12 p-0">
                        <hr style={{ border: "1px solid #d0ae83" }} />
                    </div>
                </div>

                <div className="mt-2">
                    <Footer />
                </div>
            </div>

        </>
    )
}

export default BuyerGuide
