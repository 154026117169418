import React from "react";
import "./CustomerCarousal.css";
import Slider from "react-slick";
import { BiSolidQuoteAltLeft } from "react-icons/bi";

import "bootstrap/dist/css/bootstrap.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

function CustomerCarousal() {
  function CustomNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <BsArrowRight className="customer-carosal-arrow-icon" />
      </div>
    );
  }

  function CustomPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <div>
          <BsArrowLeft className="customer-carosal-arrow-icon" />
        </div>
      </div>
    );
  }

  const data = [
    {
      customerName: "Laila Al-Khouri",
      customerdescription:
        "Muller and Co navigated Dubai's rental market, finding our ideal property at a great price.",
    },
    {
      customerName: "Mohammed Al-Rashid",
      customerdescription:
        "Professional, knowledgeable, and dedicated. We found our dream home in Dubai!",
    },
    {
      customerName: "Omar Al-Najjar",
      customerdescription:
        "Trusted Muller and Co with our investment and they exceeded expectations every step of the way.",
    },
    {
      customerName: "Laila Al-Khouri",
      customerdescription:
        "Muller and Co navigated Dubai's rental market, finding our ideal property at a great price.",
    },
    {
      customerName: "Mohammed Al-Rashid",
      customerdescription:
        "Professional, knowledgeable, and dedicated. We found our dream home in Dubai!",
    },
    {
      customerName: "Omar Al-Najjar",
      customerdescription:
        "Trusted Muller and Co with our investment and they exceeded expectations every step of the way.",
    },
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 765,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <div className="container">
      <Slider {...settings}>
        {data.map((data, i) => (
          <div className="customer-carosal-bg">
            <div className="customer-carosal-quotes">
              <BiSolidQuoteAltLeft />
            </div>

            <p className="customer-carosal-desc">{data.customerdescription}</p>
            <hr />
            <div className="customer-carosal-circle"></div>
            <p className="customer-carosal-name">{data.customerName}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CustomerCarousal;
