import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/HomePage/HomePage";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Blogs from "./Pages/Blogs/Blogs";
import PropertyDetail from "./Pages/PropertyDetail/PropertyDetail";
import OurTeam from "./Pages/OurTeam/OurTeam";
import Investment from "./Pages/Investment/Investment";
import BlogDetail from "./Pages/BlogDetail/BlogDetail";
import AboutUs from "./Pages/AboutUs/AboutUs";
import InstantValuation from "./Pages/InstantValuation/InstantValuation";
import PropertyListing from "./Pages/PropertyListing/PropertyListing";
import PropertyFloorPlan from "./Pages/PropertyFloorPlan/PropertyFloorPlan";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse/TermsOfUse";
import Cookies from "./Pages/Cookies/Cookies";
import DubaiAreaGuide from "./Pages/DubaiAreaGuide/DubaiAreaGuide";
import BuyerGuide from "./Pages/BuyerGuide/BuyerGuide";
import Careers from "./Pages/Careers/Careers";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="contact-us" element={<ContactUs/>} />
        <Route exact path="blogs" element={<Blogs/>} />
        <Route exact path="buy-property" element={<PropertyListing/>} />
        <Route exact path="property/detail/:slug" element={<PropertyDetail/>} />
        <Route exact path="our-team" element={<OurTeam/>} />
        <Route exact path="investment" element={<Investment/>} />
        <Route exact path="blog-detail" element={<BlogDetail/>} />
        <Route exact path="about-us" element={<AboutUs/>} />
        <Route exact path="instant-valuation" element={<InstantValuation/>} />
        <Route exact path="property-floor-plan" element={<PropertyFloorPlan/>} />
        <Route exact path="dubai-area-guide" element={<DubaiAreaGuide/>} />
        <Route exact path="buyers-guide" element={<BuyerGuide/>} />
        <Route exact path="careers" element={<Careers/>} />


        <Route exact path="privacy-policy" element={<PrivacyPolicy/>} />
        <Route exact path="terms-of-use" element={<TermsOfUse/>} />
        <Route exact path="cookies" element={<Cookies/>} />
      </Routes>
    </>
  );
}

export default App;
