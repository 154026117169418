import React, { useState } from "react";
import "./Footer.css";
import {
  BiLogoFacebook,
  BiLogoYoutube,
  BiLogoLinkedin,
  BiSolidPhoneCall,
  BiLocationPlus,
  BiLogoTwitter
} from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "react-router-dom";
import { BsArrowRightShort ,BsFacebook,BsInstagram, BsWhatsapp ,BsLinkedin} from "react-icons/bs";
import Button from "@mui/material/Button";



function Footer() {

  return (
    <div className="footer-background">
      <div className="footer-container">
        {/* <div
          className="row class2"
          style={{ display: "flex", alignItems: "center", paddingTop: "35px" }}
        >
          <div className="col-lg-4 contactUs-wrapper">
            <img src="/assets/icons/headphoneIcon.png" alt="..." />
            <div className="footer-contactUs-topIcon">
              <img src="/assets/icons/whiteaa1.png" alt="..." />
            </div>
            <div className="contactUs-bottomIcon">
              <img src="/assets/icons/aa2.jpeg" alt="..." />
            </div>
            <h1>Need any support for Best of Capri?</h1>
          </div>
          <div className="col-lg-8 contactUs-btn">
          <Link to="/contact">
                <Button variant="contained">
                Contact Us
                  <BsArrowRightShort
                    style={{ fontSize: "22px", marginLeft: "10px" }}
                  />
                </Button>
              </Link>
          </div>
        </div>
        <hr
          style={{
            marginBottom: "80px",
            border: "1px solid #F8FBFC",
            opacity: "20%",
          }}
        /> */}
        {/* <div className="col-lg-3 social-content-wrapper ">
            <img src="/assets/icons/whiteLogo.png" alt="..." />
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
              inventore fugiat earum{" "}
            </p>
            <div className="social-icons">
              <BiLogoFacebook
                style={{
                  background: "#112f42",
                  padding: "5px",
                  borderRadius: "50px",
                  fontSize: "25px",
                }}
              />
              <BiLogoYoutube
                style={{
                  background: "#112f42",
                  padding: "5px",
                  borderRadius: "50px",
                  fontSize: "25px",
                }}
              />
              <BiLogoInstagramAlt
                style={{
                  background: "#112f42",
                  padding: "5px",
                  borderRadius: "50px",
                  fontSize: "25px",
                }}
              />
              <BiLogoLinkedin
                style={{
                  background: "#112f42",
                  padding: "5px",
                  borderRadius: "50px",
                  fontSize: "25px",
                }}
              />
            </div>
          </div> */}
        <div className="row ">
          <div className="col-lg-3 col-md-6 col-12 important-links">
            <h3>ABOUT</h3>
            <ul>
              <li>
                <Link to="/our-team" >Our Teams</Link>
              </li>
              <li>
                <Link>Our Services</Link>
              </li>
              <li>
                <Link>News & Blog</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 col-12 important-links">
            <h3>LEGAL TERMS</h3>
            <ul>
              <li>
                <Link >Accesibility</Link>
              </li>
              <li>
                <Link to="/terms-of-use">Terms of Use</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 col-12 important-links">
            <h3>SERVICE</h3>
            <ul>
              <li>
                <Link >Request Assistance</Link>
              </li>
              <li>
                <Link>Request a Catalog</Link>
              </li>
              <li>
                <Link>Subscribe to Newsletter</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 col-12 important-links">
            <h3>LOCATION</h3>
            <p>Office/ Apartment Number: 1601
              Building Name: Iris Bay
              Street Name: 145 Al Mustaqbal
              District/Area: Business Bay
              City: Dubai
              Country: United Arab Emirates</p>
          </div>
        </div>


        <div className="row mt-5 ">

          <div className="col-lg-3 col-md-6 col-12 footer-subtitle">
            <h3>Property for sale
              in Dubai</h3>
            <ul>
              <li>
                <Link >Buy real estate in Dubai </Link>
              </li>
              <li>
                <Link>Buy apartment in Dubai </Link>
              </li>
              <li>
                <Link>Buy Villa in Dubai</Link>
              </li>

              <li>
                <Link>Buy cheap villa in Dubai </Link>
              </li>
              <li>
                <Link>Buy property in Dubai </Link>
              </li>
              <li>
                <Link>Buy property in Downtown Dubai</Link>
              </li>
              <li>
                <Link>Buy property in Business Bay</Link>
              </li>
              <li>
                <Link>Buy property in JVC </Link>
              </li>
              <li>
                <Link>Buy Villa in Palm Jumeirah</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 col-12 footer-subtitle">
            <h3>Off-plan Properties for Sale in Dubai</h3>
            <ul>
              <li>
                <Link >Buy Off-plan Apartments in Dubai</Link>
              </li>
              <li>
                <Link >Buy Off-plan Villas in Dubai</Link>
              </li>
              <li>
                <Link >Buy Off-plan Projects in Dubai</Link>
              </li>
              <li>
                <Link >Buy Off-plan Villa Projects in Dubai</Link>
              </li>
              <li>
                <Link >Buy Off-plan Apartment Projects in Dubai</Link>
              </li>
              <li>
                <Link >Buy Off-plan Property Dubai</Link>
              </li>
              <li>
                <Link >Invest Property in Dubai</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 col-12 footer-subtitle">
            <h3>Luxury Properties for Sale in Dubai</h3>
            <ul>
              <li>
                <Link >Buy Luxury Apartments in Dubai</Link>
              </li>
              <li>
                <Link>Buy Luxury Villas In Dubai</Link>
              </li>
              <li>
                <Link>Buy Luxury Homes in Dubai</Link>
              </li>
              <li>
                <Link>Buy Luxury Apartments In Dubai</Link>
              </li>
              <li>
                <Link>Rent Luxury Villas in Dubai</Link>
              </li>
              <li>
                <Link>Rent Luxury Homes in Dubai</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 col-12 footer-subtitle">
            <h3>Dubai Property Market</h3>
            <ul>
              <li>
                <Link >Top Developers in Dubai</Link>
              </li>
              <li>
                <Link>Top Areas in Dubai</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="row mt-4 pb-4">
          <div className="col-12 footer-logo-wrapper">
            <img alt="" src="/assets/MüllerCo_Logo.png" />
          </div>

        </div>


        <div className="row footer-sociallinks-wrapper">
          <div className="col-lg-4 col-md-6 col-12 footer-terms-links">
            <ul>
              <li>
                <Link to="/privacy-policy" >Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-of-use" >Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/cookies" >Cookie Policy</Link>
              </li>

            </ul>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}
            className="col-lg-4 col-md-6 col-12 footer-terms-links">
            <ul>
              <li ><Link>© 2023 Müller & Co. All Rights Reserved.</Link></li>
            </ul>
          </div>


          <div className="col-lg-4 col-md-6 col-12">
            <div className="footer-social-icons">
              <BsFacebook
                style={{
                  color:"#d0ae83",
                  fontSize: "26px",
                }}
              />
               <BiLogoTwitter
                style={{
                  color:"#d0ae83",
                  fontSize: "26px",
                }}
              />
              <BsLinkedin
                style={{
                  color:"#d0ae83",
                  fontSize: "26px",
                }}
              />
             
              <BsInstagram
                style={{
                  color:"#d0ae83",
                  fontSize: "26px",
                }}
              />
              
              
               <BiLogoYoutube
                style={{
                  color:"#d0ae83",
                  fontSize: "26px",
                }}
              />
              <BsWhatsapp
                style={{
                  color:"#d0ae83",
                  fontSize: "26px",
                }}
              />
              
            </div>
          </div>

        </div>








      </div>
      {/* <div className="copy-right-footer">
        <p>© 2023 Caprieasy. All Right Reserved</p>
      </div> */}
    </div>
  );
}

export default Footer;
