import React from 'react';
import "./OurTeam.css";

import { BsFacebook, BsTwitter } from 'react-icons/bs';
import { BiLogoLinkedin } from 'react-icons/bi';

import { Link } from "react-router-dom";
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

function OurTeam() {
    const teamdata = [
        {
            name: 'elina Deo',
            desc: 'Agent',
            image: '/assets/girl.png'
        },
        {
            name: 'Grimes Doe',
            desc: 'Agent',
            image: '/assets/girl.png'
        },
        {
            name: 'James Doe',
            desc: 'Agent',
            image: '/assets/girl.png',
        },
         {
            name: 'elina Deo',
            desc: 'Agent',
            image: '/assets/girl.png'
        },
        {
            name: 'Grimes Doe',
            desc: 'Agent',
            image: '/assets/girl.png'
        },
        {
            name: 'James Doe',
            desc: 'Agent',
            image: '/assets/girl.png',
        },
         {
            name: 'elina Deo',
            desc: 'Agent',
            image: '/assets/girl.png'
        },
        {
            name: 'Grimes Doe',
            desc: 'Agent',
            image: '/assets/girl.png'
        },
        {
            name: 'James Doe',
            desc: 'Agent',
            image: '/assets/girl.png',
        },
         {
            name: 'elina Deo',
            desc: 'Agent',
            image: '/assets/girl.png'
        },
        {
            name: 'Grimes Doe',
            desc: 'Agent',
            image: '/assets/girl.png'
        },
        {
            name: 'James Doe',
            desc: 'Agent',
            image: '/assets/girl.png',
        },
        

    ]

    return (
        <>
            <Navbar />
            <div className=" main row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <img src="/assets/main.png" alt="" />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div>
                        <img src="/assets//team.png" alt="" />
                        <h2>The most trusted names in luxury real estate.</h2>

                    </div>
                    <p> Meet our exceptional real estate agents, the driving force behind Muller and Co. Specializing in Dubai's property market, they're here to assist you with buying, selling, or renting the finest villas, apartments, townhouses, plots, and commercial properties.</p>


                </div>
            </div>
            <div className="main-container">


                <div className="row gy-4 mb-5">

                    {teamdata.map((data, i) => (
                        <div className="col-lg-2 col-md-4 col-sm-6 mt-5">
                            <div className="profile-card">
                                <div className="img" style={{ backgroundImage: `url(${data.image})` }}>
                                    <div className="social">
                                        <div className="social-icon">
                                            <BsFacebook />
                                        </div>
                                        <div className="social-icon">
                                            <BsTwitter />
                                        </div>
                                        <div className="social-icon">
                                            <BiLogoLinkedin />
                                        </div>
                                    </div>
                                </div>
                                <div className="name">
                                    <h5>{data.name}</h5>
                                    <p>{data.desc}</p>
                                </div>
                            </div>
                        </div>

                    ))}

                </div>
            </div>



            <div className="ourteam-footer">
                <div className="row main-container pt-5">
                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="p-5 prompt-consultation-content">
                            <h2>Prompt Consultation</h2>
                            <p>Fill form below and our agent will contact you shortly</p>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                                    <input type="text" placeholder="Your Name" />
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                                    <input type="text" placeholder="Your E-mail" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-5">
                                    <input type="text" placeholder="Your Phone" />
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-btn mt-5">
                                    <Link>SUBMIT</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 pt-3">
                        <div className="prompt-consultation-img">
                            <img alt="" src="/assets/Prompt-consultation.png" />
                        </div>
                        <div className="overlay_action"></div>
                    </div>
                </div>

                <div className="row main-container mt-5">
                    <div className="col-12 p-0">
                        <hr style={{ border: "1px solid #d0ae83" }} />
                    </div>
                </div>

                <div className="mt-2">
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default OurTeam
