import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import "./HomePage.css";
import { IoSearch } from "react-icons/io5";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import CustomerCarousal from "./CustomerCarousal";
import RealEstateCarousal from "./RealEstateCarousal";
import PropertyCarousal from "./PropertyCarousal";
// import RealEstateCarousal from "./RealEstateCarousal";

function HomePage() {
  const propertyData = [
    {
      title: "Lorem ipsum diler sit amet",
      description: "Lorem ipsum diler sit amet",
      price1: "$ 400,000",
      price2: "$ 400,000",
      image: "/assets/offProperty-img2.png",
    },
    {
      title: "Property 2",
      description: "Description for Property 2",
      price1: "$ 400,000",
      price2: "$ 400,000",
      image: "/assets/offProperty-img3.png",
    },
    {
      title: "Property 2",
      description: "Description for Property 2",
      price1: "$ 400,000",
      price2: "$ 400,000",
      image: "/assets/offProperty-img2.png",
    },
    {
      title: "Property 2",
      description: "Description for Property 2",
      price1: "$ 400,000",
      price2: "$ 400,000",
      image: "/assets/offProperty-img3.png",
    },
  ];

  return (
    <div className="background">
      <Navbar />

      <header className="main-header">
        <div className="homepage-content-wrapper">
          <div className="homepage-banner-heading">
            <h1>Find Your Property in Dubai</h1>
          </div>
          <div className="homepage-banner-btn">
            <div className="homepage-fillbutton">
              <Link>Residential</Link>
            </div>
            <div className="homepage-emptybutton">
              <Link>Commercial</Link>
            </div>
            <div className="homepage-emptybutton">
              <Link>OFF-PLAN</Link>
            </div>
          </div>
          <div
            id="search-autocomplete"
            class="form-outline search-autocomplete"
          >
            <input type="text" placeholder="Search.." />
            <button
              class="search-button"
              name="button_home_search"
              type="submit"
            >
              <IoSearch />
            </button>
          </div>
        </div>
      </header>

      <div className="main-container">
        <div className="banner-cards-wrapper">
          <div className="banner-cards">
            <img alt="" src="/assets/interior-design.png" />
            <h3>Developer</h3>
          </div>
          <div className="banner-cards">
            <img alt="" src="/assets/burj-khalifa.png" />
            <h3>Areas</h3>
          </div>
          <div className="banner-cards">
            <img alt="" src="/assets/investment-img.png" />
            <h3>Investment</h3>
          </div>
        </div>
      </div>

      {/* <div className="main-container">
        <RealEstateCarousal/>
      </div> */}

      <div className="main-container">
        <div className="homepage-carousal-heading">
          <h2>Discover New Properties in Dubai</h2>
        </div>
        <div className="mt-5 pb-5">
          <PropertyCarousal />
        </div>

        <div className="offProperty-plan-btn">
          <Link>Load more.... </Link>
        </div>
      </div>

      <div className="real-estate-container">
        <div className="row real-estate-section-bg">
          <div className="col-lg-5 col-md-12 col-12 mt-5">
            <div className="real-estate-content">
              <h2>The most trusted names in real estate.</h2>
              <p>
                Meet our exceptional real estate agents, the driving force
                behind Muller and Co. Specializing in Dubai's property market,
                they're here to assist you with buying, selling, or renting the
                finest villas, apartments, townhouses, plots, and commercial
                properties.
              </p>
              <Link>Get In Touch</Link>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12 mt-5">
            <RealEstateCarousal />
          </div>
        </div>
      </div>

      <div className="main-container mt-5">
        <div className="offProperty-plan-heading">
          <h2>New Off-plan Properties in Dubai</h2>
        </div>

        <div className="row mt-4">
          <div className="col-lg-5 col-md-6 col-12 mt-3">
            <div className="offProperty-plan-img">
              <img alt="" src="/assets/offProperty-img1.png" />

              <div className="offProperty-plan-content-wrapper">
                <div className="offProperty-plan-content">
                  <h3>Lorem ipsum diler sit amet</h3>
                  <p>Lorem ipsum diler sit amet</p>
                </div>
                <div className="offProperty-plan-price">
                  <p>$ 400, 000</p>
                </div>
              </div>
              <hr
                style={{
                  border: "1px solid #D0AE83",
                  width: "100%",
                  opacity: "100%",
                }}
              />
            </div>
          </div>

          <div className="col-lg-7 col-md-6 col-12">
            <div className="row">
              {propertyData.map((property, index) => (
                <div className="col-lg-6 col-md-12 col-12 mt-3">
                  <div
                    className="offProperty-plan-bgImg"
                    style={{ background: `url(${property.image})` }}
                  >
                    <div className="offProperty-plan-price-bg">
                      <p>{property.price1}</p>
                    </div>
                  </div>
                  <div className="offProperty-plan-content-wrapper">
                    <div className="offProperty-plan-content">
                      <h3>{property.title}</h3>
                      <p> {property.description}</p>
                    </div>
                    <div className="offProperty-plan-price">
                      <p>{property.price2}</p>
                    </div>
                  </div>
                  <hr
                    style={{
                      border: "1px solid #D0AE83",
                      width: "100%",
                      opacity: "100%",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="offProperty-plan-btn">
          <Link>Load more.... </Link>
        </div>
      </div>

      <div className="main-container">
        <div className="row UAEdeveloper-wrapper">
          <div className="col-lg-8 col-md-12 col-12 UAEdeveloper-content-wrapper">
            <div className="UAEdeveloper-content">
              <h2>Other Developers in UAE</h2>
              <p>Real Estate in Popular Developers</p>

              <div className="UAEdeveloper-damac-wrapper">
                <div className="UAEdeveloper-damac">
                  <img alt="" src="/assets/Damac_logo.png" />
                </div>
                <div className="UAEdeveloper-damac">
                  <img alt="" src="/assets/Damac_logo.png" />
                </div>
                <div className="UAEdeveloper-damac">
                  <img alt="" src="/assets/Damac_logo.png" />
                </div>

                <div className="UAEdeveloper-damac-btn">
                  <Link>
                    View all <BsArrowRight style={{ marginLeft: "5px" }} />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 col-12 UAEdeveloper-img p-0">
            <img alt="" src="/assets/UAEdeveloper-img.png" />
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="customer-testimonial-wrapper pb-5">
          <h3>CUSTOMER TESTIMONIALS</h3>
          <h2>What Our Customers Say</h2>
          <div className="pt-4 pb-4">
            <CustomerCarousal />
          </div>
        </div>
      </div>

      <div className="row main-container pt-5">
        <div className="col-lg-5 col-md-12 col-12">
          <div className="p-5">
            <div className="prompt-consultation-content">
              <h2>Prompt Consultation</h2>
              <p>Fill form below and our agent will contact you shortly</p>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12  mt-4">
                <div className='prompt-consultation-inputs'>
                  <input type="text" placeholder="Your Name" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12 mt-4">
                <div className='prompt-consultation-inputs'>
                  <input type="text" placeholder="Your E-mail" />
                </div>


              </div>
              <div className="col-lg-6 col-md-12 col-12  mt-5">
                <div className='prompt-consultation-inputs'>
                  <input type="text" placeholder="Your Phone" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12  mt-5">
                <div className='prompt-consultation-btn'>
                  <Link>SUBMIT</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-md-12 col-12 pt-3">
          <div className="prompt-consultation-img">
            <img alt="" src="/assets/Prompt-consultation.png" />
          </div>
          <div className="overlay_action"></div>
        </div>
      </div>

      <div className="row main-container mt-5">
        <div className="col-12 p-0">
          <hr style={{ border: "1px solid #d0ae83" }} />
        </div>
      </div>

      <div className="mt-2">
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
