import React from "react";
import "./Blogs.css";
import Navbar from "../../Components/Navbar/Navbar";
import { Link } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import Footer from "../../Components/Footer/Footer";

function Blogs() {


  const blogsData = [
    {
      heading: "Lorem ipsum doler sit amet dolor sit",
      name: "Lina Hicks",
      date: "02 May",
      verification: "Verified writer",
      image: "/assets/blog-articale-1.png"
    },
    {
      heading: "Lorem ipsum doler sit amet dolor sit",
      name: "Tyler Murray",
      date: "02 May",
      verification: "Verified writer",
      image: "/assets/blog-articale-2.png"
    },

    {
      heading: "Lorem ipsum doler sit amet dolor sit",
      name: "Warren Casey",
      date: "02 May",
      verification: "Verified writer",
      image: "/assets/blog-articale-3.png"
    },
    {
      heading: "Lorem ipsum doler sit amet dolor sit",
      name: "Lina Hicks",
      date: "02 May",
      verification: "Verified writer",
      image: "/assets/blog-articale-1.png"
    },
    {
      heading: "Lorem ipsum doler sit amet dolor sit",
      name: "Tyler Murray",
      date: "02 May",
      verification: "Verified writer",
      image: "/assets/blog-articale-2.png"
    },

    {
      heading: "Lorem ipsum doler sit amet dolor sit",
      name: "Warren Casey",
      date: "02 May",
      verification: "Verified writer",
      image: "/assets/blog-articale-3.png"
    },


  ]


  return (
    <>
      <Navbar />

      <header className="blog-header mb-5">
        <div className="">
          <div className="blog-heading-wrapper">
            <div className="blog-banner-heading">
              <img alt="" src="/assets/blogging-icon.png" />
              <h1>Blogs</h1>
            </div>
            <hr />
          </div>
          <div className="blog-header-content-wrapper">
            <div className="row blog-header-wrapper">
              <div className="col-lg-5 col-md-12 col-12 blog-headerContent-img p-0">
                <img alt="" src="/assets/blog-img1.png" />
              </div>
              <div className="col-lg-7 col-md-12 col-12 blog-headerContent-wrapper ">
                <div className="blog-header-content">
                  <h3 className="blog-feature">FEATURED</h3>
                  <h2>Lorem ipsum diler sit amet lorem sa</h2>
                  <p>
                    In this digital generation where information can be easily
                    obtained within seconds, business cards ...
                  </p>
                </div>

                <div className="blog-header-avarter-wrapper">
                  <div className="blog-headerContent-avarter">
                    <img alt="" src="/assets/redAvatar.svg" />
                  </div>
                  <div className="blog-headerContent-name">
                    <h4>Name Surname</h4>
                    <p>
                      <img alt="" src="/assets/checkIcon.svg" /> Verified writer
                    </p>
                  </div>
                  <div className="blog-headerContent-date">
                    <p>06 May</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </header>
      <div className="main-container">
        <div className="blog-whatsap-icon">
          <img alt="" src="/assets/whatsapp-icon.svg" />
        </div>
        <div className="blog-top-section">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h1 className="heading-primary text-start">Popular Articles</h1>
              <p className="p-primary">We share common trends, strategies ideas, opinions, short & long stories from the team behind company.</p>
            </div>
            <div className="col-lg-6 col-md-8 col-sm-12"
              style={{ display: 'flex', justifyContent: "end" }}>
              <Link to="/blog-detail">
                <button className="blog-view-btn">View all &#x2192;</button>
              </Link>

            </div>

          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12 col-12 mt-5">
              <div className="top-card-img-1">
                <div className="top-card-liner">
                  <div className="blog-card-body mb-3">
                    <h3 className=" top-card-heading text-center mb-1">
                      Lorem ipsum doler sit
                      amet dolor sit
                    </h3>
                    <div className="row">
                      <div className="col-2">
                        <img alt="" src="/assets/Avatar.svg" style={{ width: "40px", marginLeft: '22px' }} />
                      </div>
                      <div className="col-6">
                        <h6 className="">Lina Hicks</h6>
                        <div className="d-flex">
                          <img alt="" src="/assets/checkIcon.svg" className="verified-icon" />
                          <p className="">Verified writer</p>
                        </div>

                      </div>
                      <div className="col-4">
                        <span className="card-date">02 May</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12 mt-5">
              <div className="top-card-img-2">
                <div className="top-card-liner">
                  <div className="blog-card-body mb-3">
                    <h3 className="top-card-heading text-center mb-1">
                      Lorem ipsum doler sit
                      amet dolor sit
                    </h3>
                    <div className="row">
                      <div className="col-2">
                        <img alt="" src="/assets/Avatar.svg" style={{ width: "40px", marginLeft: '22px' }} />
                      </div>
                      <div className="col-6">
                        <h6 className="">Lina Hicks</h6>
                        <div className="d-flex">
                          <img alt="" src="/assets/Icon  3.svg" className="verified-icon" />
                          <p className="">Verified writer</p>
                        </div>

                      </div>
                      <div className="col-4">
                        <span className="card-date">02 May</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



          <div className="row mt-5 mb-5">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <h1 className="heading-primary text-start">Recent Articles</h1>
                <p className="p-primary">Here’s what we've been up to recently.</p>
              </div>
              <div className="col-lg-6 col-md-6 col-12" style={{ display: 'flex', justifyContent: "end" }}>
                <Link to="/blog-detail">
                  <button className="blog-view-btn">View all &#x2192;</button>
                </Link>

              </div>
            </div>
            {blogsData.map((data, i) => (
              <div class="col-lg-4 col-md-6 col-sm-12 mt-4">
                <div
                  style={{ backgroundImage: `url(${data.image})` }}
                  className="card-img-1" >
                  <div className="liner">
                    <div className="blog-card-body mb-3">
                      <h3 className=" p-secondary text-center mb-1">
                        {data.heading}

                      </h3>
                      <div className="row">
                        <div className="col-2">
                          <img alt="" src="/assets/Avatar.svg" style={{ width: "40px", marginLeft: '22px' }} />
                        </div>
                        <div className="col-6">
                          <h6 className=""> {data.name}</h6>
                          <div className="d-flex">
                            <img alt="" src="/assets/checkIcon.svg" className="verified-icon" />
                            <p className=""> {data.verification}</p>
                          </div>

                        </div>
                        <div className="col-4">
                          <span className="card-date"> {data.date}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            ))}
          </div>
   



      </div>




      <div className="blogs-footer">
        <div className="row main-container pt-5">
          <div className="col-lg-5 col-md-12 col-12">
            <div className="p-5 prompt-consultation-content">
              <h2>Prompt Consultation</h2>
              <p>Fill form below and our agent will contact you shortly</p>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                  <input type="text" placeholder="Your Name" />
                </div>
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                  <input type="text" placeholder="Your E-mail" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-5">
                  <input type="text" placeholder="Your Phone" />
                </div>
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-btn mt-5">
                  <Link>SUBMIT</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12 pt-3">
            <div className="prompt-consultation-img">
              <img alt="" src="/assets/Prompt-consultation.png" />
            </div>
            <div className="overlay_action"></div>
          </div>
        </div>

        <div className="row main-container mt-5">
          <div className="col-12 p-0">
            <hr style={{ border: "1px solid #d0ae83" }} />
          </div>
        </div>

        <div className="mt-2">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Blogs;
