import React, { useState } from 'react';
import "./PropertyDetail.css";
import { BsArrowLeft, BsBuildingsFill, BsFillImageFill } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Navbar from "../../Components/Navbar/Navbar";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from "react-icons/ai";



function PropertyDetail() {

  const accordionData = [
    {
      id: 1,
      title: 'Who is the developer of Sobha One?',
      content: 'The developer of the Sobha One residential complex is Sobha Realty.',
    },
    {
      id: 2,
      title: 'What are the Payment Plans for Sobha One?',
      content: 'The developer of the Sobha One residential complex is Sobha Realty.',
    },
    {
      id: 3,
      title: 'Who is the developer of Sobha One?',
      content: 'The developer of the Sobha One residential complex is Sobha Realty.',
    },
    {
      id: 4,
      title: 'What are the unit types offered at Sobha One?',
      content: 'The developer of the Sobha One residential complex is Sobha Realty.',
    },
    {
      id: 5,
      title: 'Who is the developer of Sobha One?',
      content: 'The developer of the Sobha One residential complex is Sobha Realty.',
    },
    {
      id: 6,
      title: 'Can I obtain residency if I buy property in Sobha One?',
      content: 'The developer of the Sobha One residential complex is Sobha Realty.',
    },
    {
      id: 7,
      title: 'What is the completion date of Sobha One?',
      content: 'The developer of the Sobha One residential complex is Sobha Realty.',
    },
    {
      id: 8,
      title: 'Can a foreigner buy property at Sobha One?',
      content: 'The developer of the Sobha One residential complex is Sobha Realty.',
    },



  ];

  const carouselMap = [
    {
      image: "/assets/MaskGroup.png",
    },
    {
      image: "/assets/MaskGroup.png",
    },
    {
      image: "/assets/MaskGroup.png",
    },
  ]


  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <>
      <Navbar />
      <div className="main-container">
        <div className="go-back d-flex align-items-center my-5">
          <BsArrowLeft className="text-theme" />
          <h6 className="m-0 ms-3 text-theme fw-normal">Go Back</h6>
        </div>

        <h2>Sobha One </h2>

        <div className="my-2">
          <FaMapMarkerAlt className="me-1" />
          Ras Al Khor, Dubai, UAE
          <BsBuildingsFill className="ms-4 me-1" />
          Sobha
        </div>
        <p className="text-secondary">
          Enjoy the luxury apartment with views of the Burj Khalifa, Creek Tower
          to the northeast, and Downtown to the west.
        </p>
        <div className="row header">
          <div className="col-lg-8 col-md-12 col-12 mt-3">
            <img src="/assets/MaskGroup.png" alt="" />
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-3">
            <img src="/assets/Thumbnail3.png" alt="" className="mb-5" />

            <div className="view-all-photos-img">
              <button
                variant="primary"
                onClick={handleShow}
                className="view-all-photos-btn">
                <BsFillImageFill className="text-theme me-2" />
                View All Photos
              </button>
            </div>
          </div>
        </div>

        <Modal className='property-modal' show={show} onHide={handleClose}>
          <AiOutlineClose onClick={handleClose} closeButton className="btn-close1" />
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-12'>
                  {carouselMap.map((data, i) => (
                    <div className={`carousel-item ${i === 0 ? 'active' : ''}`}>
                      <img src={data.image} alt="" />
                    </div>
                  ))}
                </div>
              </div>

              {/* <div className="carousel-item active">
                    <img src={maskGroup} alt="" />
                  </div>
                  <div className="carousel-item ">
                    <img src={maskGroup} alt="" />
                  </div>
                  <div className="carousel-item ">
                    <img src={maskGroup} alt="" />
                  </div> */}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </Modal>




        <div className="row mt-5">
          <div className="col-lg-8 col-md-12 col-12">
            <div className="property-detail row mb-3 ">
              <div className="property-card col-lg-3 col-md-6 col-12 ">
                <img src="/assets/bed1.png" alt="" />
                <p className="attributre">Property Type</p>
                <p className="fw-bold ms-2">Apartment</p>
              </div>
              <div className="property-card col-lg-2 col-md-6 col-12 ">
                <img src="/assets/bed2.png" alt="" />
                <p className="attributre">Unit Types</p>
                <p className="fw-bold ">1-4 bedroom</p>
              </div>
              <div className="property-card col-lg-2 col-md-6 col-12 ">
                <img src="/assets/blueprint.png" alt="" />
                <p className="attributre">Size</p>
                <p className="fw-bold ">1233 sq.ft.</p>
              </div>
              <div className="property-card col-lg-3 col-md-6 col-12">
                <img src="/assets/give2.png" alt="" />
                <p className="attributre">Completion Date</p>
                <p className="fw-bold">Q4 2026</p>
              </div>
              <div className="property-card col-lg-2 col-md-6 col-12">
                <img src="/assets/income1.png" alt="" />
                <p className="attributre">Payment Plan</p>
                <p className="fw-bold ">60/40</p>
              </div>

              <hr style={{ opacity: "1", color: "#EBF7FB" }} />

              <div className="property-card col-lg-3 col-md-6 col-12 ">
                <p className="fw-bold">Penthouse</p>
              </div>
              <div className="property-card col-lg-2 col-md-6 col-12">
                <p className="fw-bold">2-4 bedroom</p>
              </div>
              <div className="property-card col-lg-2 col-md-6 col-12">
                <p className="fw-bold">1233 sq.ft.</p>
              </div>
            </div>
            <h3>Description:</h3>
            <p className="text-secondary">
              Welcome to Sobha One, a sophisticated residential development
              soaring 30 to 65 stories high, offering panoramic views of Dubai
              Creek, Burj Khalifa, Creek Tower, and Downtown Dubai. Meticulously
              designed apartments with premium finishes and state-of-the-art
              fixtures provide a luxurious living experience complemented by
              world-class amenities, including an infinity pool, spa, and
              exquisite dining venues. Embrace the epitome of elegance and
              exclusivity at Sobha One, where every detail is curated to create
              an extraordinary lifestyle.
            </p>

            <hr style={{ opacity: "1", color: "#EBF7FB" }} />
            <h3>Detailed:</h3>
            <p className="text-secondary">
              Sobha One by Sobha Realty is a residential community in Sobha
              Hartland, Dubai. It comprises five interconnected towers ranging
              from 30 to 66 floors, offering 2,700 units. These include 1-4
              bedroom apartments, 2, 3, and 4-bedroom duplexes, and 1.5-bedroom
              apartments with a study room. Each property features a
              laundry/utility room, and 3+ unit options come with a maid's
              room.
              <br />
              <br />
              Strategically located in Sobha Hartland, Sobha One offers easy
              access to Downtown Dubai and the Ras Al Khor Wildlife Sanctuary.
              The development caters to diverse lifestyles with various
              apartment sizes and configurations. With laundry/utility rooms and
              maid's rooms available, these properties prioritize convenience
              and space.
            </p>

            <hr style={{ opacity: "1", color: "#EBF7FB" }} />
            <h3>Floor Plan</h3>
            <p className="text-secondary">
              Sobha One offers a classic range of 1 to 4 bedroom waterfront
              apartments available in various layouts and floor plans.
              Apartments ranging in size from one to four bedrooms, as well as
              duplexes with two to four bedrooms, are included in the "grand
              life". In these upscale homes, you can truly live a good life.
            </p>
            <Link to="/property-floor-plan">
              <button className="bg-theme border-0 text-white p-2 px-4 rounded-pill">
                See More
              </button>
            </Link>
            <hr style={{ opacity: "1", color: "#EBF7FB" }} />

            <h3>Amenities:</h3>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12 mb-3">
                <div className="custom-card">
                  <img src="/assets/plant.png" alt="" />
                  <p className="fw-bold mt-2">Sky Garden</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 mb-3">
                <div className="custom-card">
                  <img src="/assets/birdie.png" alt="" />
                  <p className="fw-bold mt-2">
                    Pitch & Putt 18 Hole Golf Course
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 mb-3">
                <div className="custom-card ">
                  <img src="/assets/garden.png" alt="" />
                  <p className="fw-bold mt-2">4 Themed Courtyards</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 mb-3">
                <div className="custom-card ">
                  <img src="/assets/terrace.png" alt="" />
                  <p className="fw-bold mt-2">Sky Terrace</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 mb-3">
                <div className="custom-card">
                  <img src="/assets/store.png" alt="" />
                  <p className="fw-bold mt-2">Retail Space</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 mb-3">
                <div className="custom-card">
                  <img src="/assets/bbq.png" alt="" />
                  <p className="fw-bold mt-2">BBQ Spaces</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 mb-3">
                <div className="custom-card">
                  <img src="/assets/gym.png" alt="" />
                  <p className="fw-bold mt-2">Gym</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 mb-3">
                <div className="custom-card ">
                  <img src="/assets/swimming-pool.png" alt="" />
                  <p className="fw-bold mt-2">Swimming Pool</p>
                </div>
              </div>
            </div>
            <h3>Location info:</h3>
            <p className="text-secondary">
              Sobha One offers residents a convenient and vibrant lifestyle.
              With its strategic location near Ras Al Khor Road, residents can
              easily access key areas of Dubai. The community provides a range
              of entertainment, leisure amenities, education institutions, and
              medical facilities, making it ideal for families. Additionally,
              proximity to shopping centers, wildlife sanctuaries, and beaches
              ensures plenty of recreational opportunities. The availability of
              full-floor units in Tower A & B adds to its value as an investment
              choice.
            </p>
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="mapouter w-100">
                  <div className="gmap_canvas">
                    <iframe
                      id="gmap_canvas"
                      src="https://maps.google.com/maps?q=Burj-E-Noor+Plaza&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                      style={{
                        height: "300px",
                        width: "100%",
                        marginTop: "1rem",
                        borderRadius: "10px",
                      }}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12">
            <div className="contact-us-box">
              <p>Starting from</p>
              <h4>AED 10,000,000</h4>
              <img src="/assets/wallet.png" alt="" />
              <hr style={{ opacity: "1", color: "#EBF7FB" }} />
              <p className="fw-bold">Request a home tour</p>
              <Link to="/contact-us">
                <button className="contact-us-btn">CONTACT US</button>
              </Link>
            </div>

            <div className="side-bar-right">
              <h3 className="fw-bold">Similar Properties</h3>
              <p className="text-secondary">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut.
              </p>

              {[1, 2, 3].map((data, i) => (
                <div>
                  <div className="similar-property-card">
                    <div className="aed">
                      {" "}
                      <p>from AED 10,000,000</p>
                    </div>
                  </div>
                  <div className="mx-3">
                    <p className="fw-bold ">Tingah Elegant House</p>
                    <FaMapMarkerAlt className="me-1" /> Business Bay
                    <div className="row mt-2 bg-light p-2">
                      <div className="col">
                        <BsBuildingsFill className="me-1" />
                        Lorem ipsum
                      </div>
                      <div className="col">
                        <img src="/assets/give.png" alt="" className="me-1" /> Q1 2026
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="faq mt-5">
          <h3 className="">FAQ about Sobha One</h3>
          <div className="row">
            {accordionData.map((item) => (
              <div className="col-lg-6 col-md-6 col-12 mt-3" key={item.id}>
                <Accordion
                  expanded={expandedAccordion === item.id}
                  onChange={handleChange(item.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-content-${item.id}`}
                    id={`panel-header-${item.id}`}
                  >
                    <Typography variant="h6">{item.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{item.content}</Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>

      </div>


      <div className="buyproperty-footer">
        <div className="row main-container pt-5">
          <div className="col-lg-5 col-md-12 col-12">
            <div className="p-5 prompt-consultation-content">
              <h2>Prompt Consultation</h2>
              <p>Fill form below and our agent will contact you shortly</p>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                  <input type="text" placeholder="Your Name" />
                </div>
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                  <input type="text" placeholder="Your E-mail" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-5">
                  <input type="text" placeholder="Your Phone" />
                </div>
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-btn mt-5">
                  <Link>SUBMIT</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12 pt-3">
            <div className="prompt-consultation-img">
              <img alt="" src="/assets/Prompt-consultation.png" />
            </div>
            <div className="overlay_action"></div>
          </div>
        </div>

        <div className="row main-container mt-5">
          <div className="col-12 p-0">
            <hr style={{ border: "1px solid #d0ae83" }} />
          </div>
        </div>

        <div className="mt-2">
          <Footer />
        </div>
      </div>



    </>
  )
}

export default PropertyDetail
