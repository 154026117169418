import React, { useState } from 'react';
import './RealEstateCarousal.css'; // Import your CSS file

import {BsArrowLeft,BsArrowRight} from 'react-icons/bs';


const Item = ({ level, imageUrl, name,desc }) => {
  const className = `item level${level}`;
  return (
    <div
      className={className}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}>
      <div className='real-estate-carosuel-card-content'>

      <h5>{name}</h5>
      <h6>{desc}</h6>
      </div>
    </div>
  );
};

const RealEstateCarousal = () => {
  const items = [
    // '/assets/offProperty-img1.png',
    // '/assets/offProperty-img2.png',
    // '/assets/offProperty-img3.png',
    '/assets/realestate-carousal1.png',
    '/assets/realestate-carousal1.png',
    '/assets/realestate-carousal1.png',
    '/assets/realestate-carousal1.png',
    '/assets/realestate-carousal1.png',
    '/assets/realestate-carousal1.png',
    // '/assets/offProperty-img1.png',
    // '/assets/offProperty-img2.png',
    // '/assets/offProperty-img3.png',
  ];
  const heading = [
    'NAME SURNAME',
    'NAME SURNAME',
    'NAME SURNAME',
    'NAME SURNAME',
    'NAME SURNAME',
    'NAME SURNAME',
    

  ];
  const desc = [
    'LOREM IPSUM DILER SIT AMET',
    'LOREM IPSUM DILER SIT AMET',
    'LOREM IPSUM DILER SIT AMET',
    'LOREM IPSUM DILER SIT AMET',
    'LOREM IPSUM DILER SIT AMET',
    'LOREM IPSUM DILER SIT AMET',
    'LOREM IPSUM DILER SIT AMET',

  ];
  const [active, setActive] = useState(1); // Initialize to 1 to start with the second image
  const [direction, setDirection] = useState('');

  const numItems = items.length;

  const generateItems = () => {
    const generatedItems = [];

    // Calculate the indices of the items to display
    const indicesToDisplay = [
      (active - 1 + numItems) % numItems,
      active,
      (active + 1) % numItems,
    ];

    // Generate the items based on the calculated indices
    for (const index of indicesToDisplay) {
      generatedItems.push(
        <Item key={index} level={index - active} imageUrl={items[index]} name={heading[index]} desc={desc[index]}  />
      );
    }

    return generatedItems;
  };

  const moveLeft = () => {
    const newActive = (active - 1 + numItems) % numItems;
    setActive(newActive);
    setDirection('left');
  };

  const moveRight = () => {
    const newActive = (active + 1) % numItems;
    setActive(newActive);
    setDirection('right');
  };

  return (
    <div id="carousel" className="noselect">
      <div className="arrow arrow-left" onClick={moveLeft}>
      <BsArrowLeft/>
      </div>
      <div className={`transition-container ${direction}`}>
        {generateItems()}
      </div>
      <div className="arrow arrow-right" onClick={moveRight}>
      <BsArrowRight/>
      </div>
    </div>
  );
};

export default RealEstateCarousal;
