import React from 'react';
import "./DubaiAreaGuide.css";

import {BsArrowRight} from 'react-icons/bs';
import { BsBuildingsFill } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';


function DubaiAreaGuide() {
    const projectCard =[
        {
            image:"/assets/dubai-areaguide-hotel1.png",
            heading:"Jumeirah",
            projects:"40",
        },
        {
            image:"/assets/dubai-areaguide-hotel1.png",
            heading:"Jumeirah",
            projects:"40",
        },
        {
            image:"/assets/dubai-areaguide-hotel1.png",
            heading:"Jumeirah",
            projects:"40",
        },
        {
            image:"/assets/dubai-areaguide-hotel1.png",
            heading:"Jumeirah",
            projects:"40",
        },
        {
            image:"/assets/dubai-areaguide-hotel1.png",
            heading:"Jumeirah",
            projects:"40",
        },
        {
            image:"/assets/dubai-areaguide-hotel1.png",
            heading:"Jumeirah",
            projects:"40",
        },
    ]

    const availabeProjectData = [
        {
            image:"/assets/dubai-areaguide-hotel1.png",
          price: "AED 10,000,000",
          heading: "Tingah Elegant House",
          location: "Business Bay",
        },
        {
            image:"/assets/dubai-areaguide-hotel1.png",
          price: "AED 10,000,000",
          heading: "Tingah Elegant House",
          location: "Business Bay",
        },
        {
            image:"/assets/dubai-areaguide-hotel1.png",
          price: "AED 10,000,000",
          heading: "Tingah Elegant House",
          location: "Business Bay",
        },
      ];



  return (
    <>
    <Navbar/>
     <div className=" dubai-main row">
        <div className="col-lg-7 col-md-12 col-sm-12">
          <img src="/assets/dubai-areaguide-img.png" alt="" />
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12">
          <div>
            <img src="/assets/dubai-areaguide-burj.png" alt="" />
            <h2 className="">Dubai</h2>
          </div>
          <p>
            orem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna
          </p>
        </div>
      </div>
      <div className="main-container">
      <h3 className="text-center">About Dubai</h3>
        <div className="d-flex gap-4 mt-5">
          <div>
            <p>
              Overlooking an 18-hole championship golf course, Dubai Hills
              Estate by ace developer Emaar Properties is a truly one of its
              kind prime community. spanning over 2,700 acres, this mixed-used
              development has got all the right elements and is soon likely to
              become an integral part of the Mohammed Bin Rashid City.
            </p>
            <p>
              Residents of this pristine community will surely appreciate the
              facilities on offer. Surrounded by landscaped parks and gardens,
              this development also includes walkways and open areas- good for
              those who prefer to lead an active lifestyle. This full-fledged
              project is in itself a city within a city.
            </p>
            <p>
            The golf course nearby will benefit those who want to perfect their game and technique. Moreover, the clubhouse looks like a good place to meet and socialize with like-minded people. There are other recreational facilities on offer such as play area for children, world-class schools, healthcare, hospitality and much more.
            </p>
          </div>
          <div>
            <p>
            Located within this opulent community is Dubai Hills Park. Spreading over 180,000 square metres, it promises to be a great outdoor lifestyle hub. Designed to blend in with the surrounding landscape, the park offers a 2.5 km long jogging track, splash park, volley and tennis court, skate park and outdoor gym. While Downtown Dubai is 15 mins away, the famed Dubai Hills Mall is in close proximity.
            </p>
            <p>
            Going by the facilities on offer, it looks to be a good place to sit down and relax.
            </p>
            <p>
            Considering the fact that this development is lined with all the comfort and luxury, the residents and visitors will surely admire the serene environment. The wide range of prime real estate offering that includes the likes of townhouses and luxury villas, there’s a great variety of options on offer.
            </p>
          </div>
        </div>
        <h3 className='text-center my-5'>Areas in Dubai</h3>
        <p className="text-center">Take a look at the new off-plan developments in and around Dubai Take a look at some of the attractive investment offers.</p>

        <div className="row">


        {projectCard.map((data,i)=>(
          <div className="col-4 dubai">
                <div className="dubai-img" style={{backgroundImage:`url(${data.image})`}}>

                </div>
                <div className="dubai-content">
                    <h4>{data.heading}</h4>
                    <button>{data.projects} Projects <BsArrowRight/></button>
                </div>
            </div>
            ))}
            </div>

            <h3 className='text-center my-5'>Available projects</h3>
        <p className="text-center">Take a look at the new off-plan developments in and around Dubai Take a look at some of the attractive investment offers.</p>



        <div className="row">
          {availabeProjectData.map((data, i) => (
            <div className="col-4">
              <div>
                <div
                  className="dubai-avaible-project-card"
                  style={{ backgroundImage: `url(${data.image})` }}
                >
                  <div className="aed">
                    <p>from {data.price}</p>
                  </div>
                </div>
                <div className="mx-3">
                  <p className="fw-bold ">{data.heading}</p>
                  <FaMapMarkerAlt className="me-1" /> {data.location}
                  <div className="row mt-2 bg-light p-2">
                    <div className="col">
                      <BsBuildingsFill className="me-1" />
                      Lorem ipsum
                    </div>
                    <div className="col">
                      <img src="assets/dubai-areaguide-give.png" alt="" className="me-1" /> Q1 2026
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>


      <div className="dubaiarea-guide-footer">
        <div className="row main-container pt-5">
          <div className="col-lg-5 col-md-12 col-12">
            <div className="p-5 prompt-consultation-content">
              <h2>Prompt Consultation</h2>
              <p>Fill form below and our agent will contact you shortly</p>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                  <input type="text" placeholder="Your Name" />
                </div>
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-4">
                  <input type="text" placeholder="Your E-mail" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-inputs mt-5">
                  <input type="text" placeholder="Your Phone" />
                </div>
                <div className="col-lg-6 col-md-12 col-12 prompt-consultation-btn mt-5">
                  <Link>SUBMIT</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12 pt-3">
            <div className="prompt-consultation-img">
              <img alt="" src="/assets/Prompt-consultation.png" />
            </div>
            <div className="overlay_action"></div>
          </div>
        </div>

        <div className="row main-container mt-5">
          <div className="col-12 p-0">
            <hr style={{ border: "1px solid #d0ae83" }} />
          </div>
        </div>

        <div className="mt-2">
          <Footer />
        </div>
      </div>
   </>
  )
}

export default DubaiAreaGuide
